import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import HeaderInner from "./HeaderInner";
import configureStore from "../../../store/configureStore";
import "./App.css";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Button } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { get_staff_details, update_staff } from "./actions/action-staff";
import { history } from "../../../reducers";
import { is } from "immutable";
import Loader from "../../common/Loader";
import { ControlCameraOutlined } from "@material-ui/icons";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  input: {
    marginBottom: 10,
  },
}));

export default function EditStaff() {
  const classes = useStyles();
  const dispatch = useDispatch();
  let userProfile;

  const { staffdetails, showGlobalLoader } = useSelector(
    (state) => state.staffs
  );

  const [state, setState] = React.useState();
  const [isAdmin, setAdmin] = React.useState(false);

  const { activeOuid } = useSelector((state) => state.permission);

  const handleCheck = (event) => {
    // setChecked(event.target.checked);
    console.log("[event]", event);
    console.log("[event.currentTarget.checked]", event.currentTarget.checked);

    console.log("[event.target.checked]", event.target.checked);
    if (event.target.checked) {
      console.log("Before set state", state);
      setState({ ...state, appRoles: ["hrm=admin"] });
      setAdmin(!isAdmin);

      console.log("After set state", state);
    } else {
      console.log("Before set state", state);

      setState({ ...state, appRoles: ["hrm=user"] });
      setAdmin(!isAdmin);

      console.log("After set state", state);
    }
    console.log("state", state);
  };

  const handleChange = (event) => {
    console.log("event.target.id", event.target.id);
    console.log("event.target.value", event.target.value);
    console.log("event.target.name", event.target.name);

    setState({ ...state, [event.target.id]: event.target.value });
  };

  const handleSubmit = () => {
    dispatch(update_staff(state, activeOuid, staffdetails.username));
    history.push(`/${activeOuid}/staff`);
  };

  useEffect(() => {
    userProfile = window.location.pathname.split("/");
    var username = userProfile[3];
    console.log("userProfile", username);
    const fetchData = async (dispatch) => {
      await dispatch(get_staff_details(activeOuid, username));
      console.log("app role", store.getState().staffs.staffdetails.appRoles);
      setState({
        ...state,
        name: store.getState().staffs.staffdetails.name,
        username: store.getState().staffs.staffdetails.username,
        phone_number: store.getState().staffs.staffdetails.phone_number,
        jobTitle: store.getState().staffs.staffdetails.jobTitle,
        appRoles: store.getState().staffs.staffdetails.appRoles,
      });
      setAdmin(
        store.getState().staffs.staffdetails.appRoles.includes("hrm=admin")
      );
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <>
      <HeaderInner pageTitle="Edit staff" />
      <div className="container--narrow">
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <form className={classes.root} autoComplete="off">
            <h1>Edit staff</h1>
            <TextField
              value={staffdetails.username}
              id="username"
              label="Email"
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleChange}
              disabled
            />
            <TextField
              defaultValue={staffdetails.name}
              id="name"
              label="Name"
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleChange}
            />

            <TextField
              defaultValue={staffdetails.phone_number}
              id="phone_number"
              label="Phone Number"
              name="phone_number"
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleChange}
            />

            <TextField
              defaultValue={staffdetails.jobTitle}
              id="jobTitle"
              name="jobTitle"
              label="Job Title"
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleChange}
            />
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCheck}
                    key={Math.random()}
                    id="isAdmin"
                    name="isAdmin"
                    color="primary"
                    checked={isAdmin}
                  />
                }
                label="Is administrator?"
              />
              <p>Check if the user is an administrator</p>
            </FormGroup>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
            >
              Save
            </Button>
          </form>
        )}
      </div>
    </>
  );
}
