const dev = {
  api: {
    invokeUrl: "https://74udikyx6b.execute-api.eu-west-2.amazonaws.com/dev",
    coursesUrl: "https://9pxmy7egh6.execute-api.eu-west-2.amazonaws.com/dev",
    woUrl: "https://sni1mg4xql.execute-api.eu-west-2.amazonaws.com/dev",
    hrmUrl: "https://dyumpj2c3k.execute-api.eu-west-2.amazonaws.com/dev",
  },
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_pApwzdSKZ",
    APP_CLIENT_ID: "43a1utognucnoa1rjbr25mu8eb",
  },
  payemntSecretName: "ibc_razorpay",
  razorpayId: "rzp_test_pOy0pZDFzJ8md8",
};

const test = {
  api: {
    invokeUrl: "https://74udikyx6b.execute-api.eu-west-2.amazonaws.com/dev",
    coursesUrl: "https://9pxmy7egh6.execute-api.eu-west-2.amazonaws.com/dev",
    woUrl: "https://sni1mg4xql.execute-api.eu-west-2.amazonaws.com/dev",
    hrmUrl: "https://dyumpj2c3k.execute-api.eu-west-2.amazonaws.com/dev",
  },
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_pApwzdSKZ",
    APP_CLIENT_ID: "43a1utognucnoa1rjbr25mu8eb",
  },
  payemntSecretName: "ibc_razorpay",
  razorpayId: "rzp_test_pOy0pZDFzJ8md8",
};

const prod = {
  api: {
    invokeUrl: "https://b4p0m814y9.execute-api.ap-south-1.amazonaws.com/prod",
    coursesUrl: "",
    woUrl: "",
    hrmUrl: "https://y6itwbhcfc.execute-api.ap-south-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "ap-south-1",
    USER_POOL_ID: "ap-south-1_0y28AXp88",
    APP_CLIENT_ID: "1nui5e10qnilke0agprk2hmihe",
  },
  payemntSecretName: "",
  razorpayId: "",
};

let config = dev;
if (process.env.REACT_APP_STAGE) {
  switch (process.env.REACT_APP_STAGE.trim()) {
    case "dev":
      config = dev;
      break;
    case "test":
      config = test;
      break;
    case "prod":
      config = prod;
      break;
    default:
      config = dev;
      break;
  }
}

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
