import React from 'react';
import SidebarWrapper from '../../layout/SidebarWrapper';
import { connect } from 'react-redux';
import configureStore from '../../../store/configureStore';
import Paper from '@material-ui/core/Paper';
import Suggestion from '../../assets/images/ibcImages/objects-lightbulb.svg';
import Faq from '../../assets/images/ibcImages/objects-messages.svg';
import Call from '../../assets/images/ibcImages/24-hours.png';
import Email from '../../assets/images/ibcImages/email.png';
import { history } from '../../../reducers';


const store = configureStore();

export default function Support(props) {
  const { investor } = props;
  const { orgunits, showGlobalLoader } = store.getState().orgunit;
  const { permissions, activeOuid } = store.getState().permission;
  return (
    <div className="page">
      <SidebarWrapper
        auth={props.auth}
        permissions={permissions}
      />
      <div className='container'>
        <div className="HelpPageWrapper">
          <h1>Help Center</h1>
          <Paper className="HelpCategoryWrapper">
            {/* <a href="#" className="HelpCategoryItem">
              <div><img src={Documentation} alt="" /></div>
              <div>
                <p className="HelpCategoryItemTitle">Documentation</p>
                <p className="HelpCategoryItemText">Get help using and administering products</p>
                <div className="btn">View Documentation</div>
              </div>
            </a> */}
            <a href="#" className="HelpCategoryItem" onClick={() => { history.push(`/${activeOuid}/faq`); }}>
              <div><img src={Faq} alt="" /></div>
              <div>
                <p className="HelpCategoryItemTitle">FAQ</p>
                <p className="HelpCategoryItemText">Find Frequently Asked Questions.</p>
                <div className="btn">View FAQ</div>
              </div>
            </a>
            <a href="mailto:operations.aamshe@gmail.com" className="HelpCategoryItem">
              <div><img src={Email} alt="" /></div>
              <div>
                <p className="HelpCategoryItemTitle">Email</p>
                <p className="HelpCategoryItemText">Send us an email</p>
                <div className="btn">Email</div>
              </div>
            </a>
            <a href="tel:9483870155" className="HelpCategoryItem">
              <div><img src={Call} alt="" /></div>
              <div>
                <p className="HelpCategoryItemTitle">Call us</p>
                <p className="HelpCategoryItemText">Our support team available 24x7</p>
                <div className="btn" >Call</div>
              </div>
            </a>

          </Paper>
        </div>
      </div>
      
    </div>
  );
}