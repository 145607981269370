import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderInner from "./HeaderInner";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Button } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import configureStore from "../../../store/configureStore";
import "./App.css";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { history } from "../../../reducers";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { get_leave_config } from "./actions/action-leave";
import RemoveIcon from '@material-ui/icons/Remove';

const store = configureStore();

const useStyles = makeStyles((theme) => ({
    radioButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px 0',
    }
}));

function addLeaveType(){
    
}


export default function LeaveSetup(){
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();

    const initialState = {
        config: {
        //   in: null,
        //   out: null,
        //   gracePeriod: 30,
        //   minWorkDuration: 360,
        //   type: "attendance",
        },
      };

    const [age, setAge] = React.useState('');
    const [showForm, setShowForm] = React.useState(false);

    const { activeOuid } = store.getState().permission;

    const [configState, setState] = useState(initialState);

    const { leaveConfig, showGlobalLoader } = useSelector(
        (state) => state.leaves
      );

    const fetchData = async (dispatch) => {
        await dispatch(get_leave_config(activeOuid));
        console.log("get_leave_config done");
        console.log("store.getState().leave", store.getState().leaves);
        if (store.getState().leaves.leaveConfig > 0) {
          console.log("leaveConfig.length > 0");
    
          setState({
            config: store.getState().leave.leaveConfig[0].data,
          });
          console.log("setState done");
        }
    };

    const handleCreateClick = (status) => {
        setShowForm( status );
    };
    
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const handleSubmit = () => {
        console.log("handleSubmit", configState.config);
        console.log(
          " store.getState().leave.leaveConfig",
          store.getState().leave.leaveConfig
        );
    
        console.log(
          "configId",
          store.getState().leave.leaveConfig[0].data.identifier
        );
        dispatch(
        //   update_attendance_config(
        //     configState.config,
        //     activeOuid,
        //     store.getState().attendance.attendanceConfig[0].data.identifier
        //   )
        );
      };

    

    function renderLeaveTypes(leaveToRender){
        if(leaveToRender.length === 0){
            return(
                <div>No leave types.</div>
            )
        }
        
        return(
            <div className="leaveSetupCard">
                <div>
                    <div className="leaveSetupCardHeader">
                        <div>Sick</div>
                        <div>
                            <IconButton 
                                edge="start"
                                color="primary" 
                                aria-label="menu"
                                onClick={() => history.push(`/edwin/attendancesetup`)}
                            >
                                <EditIcon />
                            </IconButton>
                            <Switch
                                // checked={state.checkedA}
                                // onChange={handleChange("checkedA")}
                                value="checkedA"
                                color="primary"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </div>
                        
                        
                    </div>
                    <div>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Credit Cycle</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={age}
                                        onChange={handleChange}
                                        ful
                                        label="Credit cycle"
                                    >
                                        <MenuItem value='Anualy'>Anualy</MenuItem>
                                        <MenuItem value='Monthly'>Monthly</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField 
                                    id="days" 
                                    label="Days" 
                                    fullWidth 
                                    variant="outlined" 
                                    className={classes.input}
                                    />
                            </Grid>

                        </Grid>
                    </div>
                </div>
            </div>
        )
    }
    useEffect(() => {
        async function fetchData(dispatch) {
            await dispatch(get_leave_config(activeOuid));
            console.log("get_leave_config done");
            console.log(
            "store.getState().attendance.leaveConfig",
            store.getState().leaves.leaveConfig
            );
            console.log("leaveConfig", leaveConfig);
    
            if (store.getState().leaves.leaveConfig.length > 0) {
            console.log("leaveConfig.length > 0");
    
            setState({
                config: store.getState().leaves.leaveConfig[0].data,
            });
            console.log("setState done");
            }
        }
        fetchData(dispatch);
        }, []);

    return (
        <div>
            <HeaderInner />
            <div className="container--narrow">
                <FormControl component="fieldset" className={classes.radioButton}>
                    <RadioGroup row aria-label="position" name="position" defaultValue="top" >
                        <FormControlLabel value="Calender Year" control={<Radio color="primary" />} label="Calender Year" />
                        <FormControlLabel value="Financial  Year" control={<Radio color="primary" />} label="Financial  Year" />
                    </RadioGroup>
                </FormControl>
                {
                    renderLeaveTypes(leaveConfig)
                }
                <a onClick={() => handleCreateClick(true)} className="leaveCreateButtonWrapper">Add Leave Type + </a>
                {
                    showForm && 
                    <div >  
                        <h3>New leave type</h3>
                        <div className="leaveSetupCard">
                            <div>
                                <div className="leaveSetupCardHeader">
                                    <div>Sick</div>
                                    <div>
                                        <IconButton 
                                            edge="start"
                                            color="primary" 
                                            aria-label="menu"
                                            onClick={() => handleCreateClick(false)}
                                            
                                        >
                                            <RemoveIcon />
                                        </IconButton>
                                        {/* <Switch
                                            // checked={state.checkedA}
                                            // onChange={handleChange("checkedA")}
                                            value="checkedA"
                                            color="primary"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        /> */}
                                    </div>
                                    
                                    
                                </div>
                                <div>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                                <InputLabel id="demo-simple-select-outlined-label">Credit Cycle</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={age}
                                                    onChange={handleChange}
                                                    ful
                                                    label="Credit cycle"
                                                >
                                                    <MenuItem value='Anualy'>Anualy</MenuItem>
                                                    <MenuItem value='Monthly'>Monthly</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField 
                                                id="days" 
                                                label="Days" 
                                                fullWidth 
                                                variant="outlined" 
                                                className={classes.input}
                                                />
                                        </Grid>

                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="HrmAppHomePageButtonWrapper">
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit}
                    >
                        Save
                    </Button>
                </div>
            </div>
            
        </div>
    );
        
    
}
