import React, { Component } from "react";
import HeaderInner from "./HeaderInner";
import { Button } from "@material-ui/core";
import configureStore from "../../../store/configureStore";
import "./App.css";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { history } from "../../../reducers";

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import avatar from './images/Frame.svg';

const store = configureStore();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: '0 auto',
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Sick', 159, 6.0),
  createData('Casual', 237, 9.0),
  createData('Eraned', 262, 16.0),
  createData('Sick', 305, 3.7),
];

export default function LeavesSingleUser(){
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  const handleChangeIndex = (index) => {
      setValue(index);
  };


    return (
        <>
          <HeaderInner />
            <div className="container--narrow">
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label="PENDING" {...a11yProps(0)} />
                  <Tab label="CLOSED" {...a11yProps(1)} />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
              <TabPanel value={value} index={0} dir={theme.direction} >
                <div className="leaveListPendingCard">
                  <div className="leaveListPendingCardHeader">
                    <div className="leaveListPendingCardProfile">
                      <img src={avatar}/>
                      <div>
                        <div className="leaveListPendingCardProfileName">Ashok Mishra</div>
                        <div className="leaveListPendingCardProfileDesignation">8138858555</div>
                      </div>
                    </div>
                  </div>
                  <div className="leaveListPendingCardBody">
                    <TableContainer>
                      <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Type </TableCell>
                            <TableCell>Total Leave</TableCell>
                            <TableCell>Left</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <TableRow key={row.name}>
                              <TableCell component="th" scope="row">
                                {row.name}
                              </TableCell>
                              <TableCell >{row.calories}</TableCell>
                              <TableCell >{row.fat}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  
                </div>
                
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <div className="leaveListPendingCard">
                  <div className="leaveListPendingCardHeader">
                    <div className="leaveListPendingCardProfile">
                      <img src={avatar}/>
                      <div>
                        <div className="leaveListPendingCardProfileName">Ashok Mishra</div>
                        <div className="leaveListPendingCardProfileDesignation">8138858555</div>
                      </div>
                    </div>
                    <div className="leaveRejectedIndicator">Rejected</div>
                  </div>
                  <div className="leaveListPendingCardBody">
                    <div>
                      <div className="InfoLabel">From</div>
                      <div className="InfoData">1/20/2022</div>
                    </div>
                    <div>
                      <div className="InfoLabel"> To</div>
                      <div className="InfoData">2/20/2022</div>
                    </div>
                    <div>
                      <div className="InfoLabel">Days</div>
                      <div className="InfoData">2</div>
                    </div>
                    
                  </div>
                  <div className="leaveListPendingCardFooter">
                    <div className="InfoLabel">Purpose</div>
                    <div className="InfoData">Personal Issues</div>
                  </div>
                  <div className="leaveListPendingCardFooter">
                    <div className="InfoLabel">Reason for rejection</div>
                    <div className="InfoData">Work pending</div>
                  </div>
                </div>
                <div className="leaveListPendingCard">
                  <div className="leaveListPendingCardHeader">
                    <div className="leaveListPendingCardProfile">
                      <img src={avatar}/>
                      <div>
                        <div className="leaveListPendingCardProfileName">Ashok Mishra</div>
                        <div className="leaveListPendingCardProfileDesignation">8138858555</div>
                      </div>
                    </div>
                    <div className="leaveApprovedIndicator">Approved</div>
                  </div>
                  <div className="leaveListPendingCardBody">
                    <div>
                      <div className="InfoLabel">From</div>
                      <div className="InfoData">1/20/2022</div>
                    </div>
                    <div>
                      <div className="InfoLabel"> To</div>
                      <div className="InfoData">2/20/2022</div>
                    </div>
                    <div>
                      <div className="InfoLabel">Days</div>
                      <div className="InfoData">2</div>
                    </div>
                    
                  </div>
                  <div className="leaveListPendingCardFooter">
                    <div className="InfoLabel">Purpose</div>
                    <div className="InfoData">Personal Issues</div>
                  </div>
                  <div className="leaveListPendingCardFooter">
                    <div className="InfoLabel">Reason for rejection</div>
                    <div className="InfoData">Work pending</div>
                  </div>
                </div>
              </TabPanel>
            </SwipeableViews>
            <div className="HrmAppHomePageButtonWrapper">
              <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => { history.push(`/edwin/applyleave`) }}
              >
                  Apply
              </Button>
            </div>
          </div>
        </>
    );
        
    
}
