import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ImageIcon from '@material-ui/icons/Image';
import { history } from '../../../../reducers';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      margin: '0 auto',
    },
    avatar: {
        textTransform: "uppercase",
    }
  }));

export default function  PayrollCard(props){
    const classes = useStyles();
    const { activeOuid } = useSelector((state) => state.permission);
    
    return (
        <>
            <ListItem
                onClick={() => history.push(`/${activeOuid}/${props.link}/${props.item.username}`)}
                >
                <ListItemAvatar>
                    <Avatar>
                      <ImageIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={props.item.name}
                    secondary={props.status}
                />
                <ListItemSecondaryAction>
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="More" onClick={() => history.push(`/${activeOuid}/${props.link}/${props.item.username}`)}>
                        <ChevronRightIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                </ListItemSecondaryAction>
            </ListItem>
        </>
      
    );
}
