import React, { Component } from 'react';
import { connect } from 'react-redux';
import configureStore from '../../store/configureStore';
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Logo from '../assets/images/ibcImages/weblogoblue.png';
import HomeIcon from '../assets/images/ibcImages/Home.png';
import UserIcon from '../assets/images/ibcImages/Profile.png';
import NotificationIcon from '../assets/images/ibcImages/Notify.png';
import MessageIcon from '../assets/images/ibcImages/Support.png';


const store = configureStore();

const useStyles = makeStyles((theme) => ({
  
  footer:{
    background: "#132749",
    color: '#fff',
    padding: 50,
    fontSize: 16,
    textAlign: 'center',
    // [theme.breakpoints.up("xs")]: {
    //   display: "flex",
    // },
    // [theme.breakpoints.up("md")]: {
    //   display: "none",
    // },
  },
  footerLogo:{
    margin: '0 auto',
  }
  
}));

function Footer() {
  const classes = useStyles();


  const { activeOuid } = store.getState().permission;
  return (
    <>
    
    <div className={classes.footer}>
      <div className='container'>
        <div className='split alignStart'>
          
        </div>
      </div>
    </div >
    
    </>
  )
}

const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
    // get_user_profile_details: (username) => dispatch(get_user_profile_details(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);