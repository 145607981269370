import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { jsPDF } from "jspdf";
import { Button } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import downloadImage from '../images/downloadpdfimage.png';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import html2canvas from "html2canvas";
import configureStore from "../../../../store/configureStore";


const store = configureStore();

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        maxWidth: 700,
        margin: '0 auto',
    },
    payslipHeading: {
        textAlign: 'center',
        marginTop: 15,
    },
    payslipDes: {
        textTransform: 'capitalize',
        paddingLeft: 15,
    },
    tableHeading: {
        background: '#dedede'
    },
    buttonRed: {
        marginTop: 15,
    }
  }));

const TAX_RATE = 0.07;


function calcTotalDeductions(payroll) {
    var totalDeductions = 0;
    for (let index = 0; index < payroll.deductions.length; index++) {
        var amount = parseFloat(payroll.deductions[index].amount);
        totalDeductions = totalDeductions + amount;
    }
    console.log("Total deductions", totalDeductions);
    return totalDeductions;
}

function calcTotalEarnings(payroll) {
    var totalEarnings = 0;
    for (let index = 0; index < payroll.earnings.length; index++) {
        var amount = parseFloat(payroll.earnings[index].amount);
        totalEarnings = totalEarnings + amount;
    }
    console.log("Total deductions", totalEarnings);
    return totalEarnings;
}

function priceRow(qty, unit) {
    return qty * unit;
}

function createRow(desc, qty, unit) {
    const price = priceRow(qty, unit);
    return { desc, qty, unit, price };
}

function subtotal(items) {
    return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

const rows = [
    createRow('Income tax', 100),
    createRow('HRA', 10),
    createRow('TA', 2),
];

const invoiceSubtotal = subtotal(rows);
const invoiceTaxes = TAX_RATE * invoiceSubtotal;
const invoiceTotal = invoiceTaxes + invoiceSubtotal;

export default function  Payslip(props){
    const {payroll} = props;
    const { details } = store.getState().myProfile;

    console.log("payroll", payroll);
    const classes = useStyles();
    const doc = new jsPDF(
        {
            orientation: "p",
            unit: 'pt',
            format: 'a4',
          }
    );
    let wantedTableWidth = 100;
    let pageWidth = doc.internal.pageSize.width;
    let margin = (pageWidth - wantedTableWidth) / 2;
    
    const pdfRef = useRef(null);
    var content = document.getElementById("payslipTable");
    console.log("content", content);
    // console.log("document.body", document.body);
    window.html2canvas = html2canvas;

    const handleDownload = () => {
        const content = pdfRef.current;
        // doc.html(content);
        // doc.text("Hello world!", 10, 10);
        doc.html(content, {
            callback: function(doc) {
                console.log("in callback");
                doc.save("payslip.pdf");
            }
            });
        
      } 
    return (
        <>
            <TableContainer component={Paper} id="payslipTable" className={classes.tableContainer} ref={pdfRef}>
                <h1 className={classes.payslipHeading}>Payslip</h1>
                <div className={classes.payslipDes}><b>Month: </b>{payroll.month}</div>
                <div className={classes.payslipDes}><b>Employee Name: </b>{payroll.name}</div>
                <div className={classes.payslipDes}><b>Company Name: </b>{details.data.tenant}</div>
                <Table className={classes.table} aria-label="spanning table">
                    <TableHead>
                        
                        <TableRow>
                            <TableCell align="center" colSpan={4} className='bold'>
                            Earnings
                            </TableCell>
                            {/* <TableCell align="right">Price</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow className={classes.tableHeading}>
                            <TableCell className='bold'>Description</TableCell>
                            <TableCell className='bold' align="right">Amount</TableCell>
                        </TableRow>
                        {payroll.earnings.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell align="right">Rs {row.amount}/-</TableCell>
                            </TableRow>
                        ))}
                        
                        <TableRow>
                            <TableCell align="center" colSpan={4} className='bold'> 
                            Deductions
                            </TableCell>
                            {/* <TableCell align="right">Price</TableCell> */}
                        </TableRow>
                        <TableRow className={classes.tableHeading}>
                            <TableCell className='bold'>Description</TableCell>
                            <TableCell className='bold' align="right">Amount</TableCell>
                        </TableRow>
                        {payroll.deductions.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell align="right">Rs {row.amount}/-</TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell className='bold'>Total Deductions</TableCell>
                            {/* <TableCell align="right">{`${(TAX_RATE * 100).toFixed(0)} %`}</TableCell> */}
                            <TableCell align="right">Rs {calcTotalDeductions(payroll)}/-</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='bold'>Total Earnings</TableCell>
                            <TableCell align="right">Rs {calcTotalEarnings(payroll)}/-</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell className='bold'>Net salary</TableCell>
                            <TableCell align="right" className='bold'>Rs {calcTotalEarnings(payroll) - calcTotalDeductions(payroll)}/-</TableCell>
                        </TableRow>
                        
                    </TableBody>
                </Table>
                </TableContainer>
            <div className="payslipDownloadContainer">
                {/* <img src={downloadImage} /> */}
                <Button
                    // size="small"
                    variant="contained"
                    color="primary"
                    className={classes.buttonRed}
                    startIcon={<GetAppIcon />}
                    onClick={() => handleDownload()}
                >
                Download Payslip
                </Button>
            </div>
        </>
        
    );
}
