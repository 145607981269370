import {
  MARK_ATTENDANCE_REQUESTED,
  MARK_ATTENDANCE_SUCCESS,
  MARK_ATTENDANCE_ERRORED,
  GET_ALL_ATTENDANCE_REQUESTED,
  GET_ALL_ATTENDANCE_SUCCESS,
  GET_ALL_ATTENDANCE_ERRORED,
  GET_MY_ATTENDANCE_REQUESTED,
  GET_MY_ATTENDANCE_SUCCESS,
  GET_MY_ATTENDANCE_ERRORED,
  PUNCH_IN_OUT_REQUESTED,
  PUNCH_IN_OUT_SUCCESS,
  PUNCH_IN_OUT_ERRORED,
  UPDATE_ATTENDANCE_CONFIG_REQUESTED,
  UPDATE_ATTENDANCE_CONFIG_SUCCESS,
  UPDATE_ATTENDANCE_CONFIG_ERRORED,
  CREATE_ATTENDANCE_CONFIG_REQUESTED,
  CREATE_ATTENDANCE_CONFIG_SUCCESS,
  CREATE_ATTENDANCE_CONFIG_ERRORED,
  GET_ATTENDANCE_CONFIG_REQUESTED,
  GET_ATTENDANCE_CONFIG_SUCCESS,
  GET_ATTENDANCE_CONFIG_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: true,
  attendance: [],
  myAttendance: [],
  attendanceConfig: {},
};

export function attendance(state = defaultState, { type, payload }) {
  switch (type) {
    case MARK_ATTENDANCE_REQUESTED:
      console.log("REGISTER_STAFF_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
        myAttendance: []
      };

    case MARK_ATTENDANCE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        myAttendance: payload
      };

    case MARK_ATTENDANCE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_MY_ATTENDANCE_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
        myAttendance: [],
      };

    case GET_MY_ATTENDANCE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        myAttendance: payload,
      };

    case GET_MY_ATTENDANCE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_ATTENDANCE_CONFIG_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
        attendanceConfig: {},
      };

    case GET_ATTENDANCE_CONFIG_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        attendanceConfig: payload,
      };

    case GET_ATTENDANCE_CONFIG_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case CREATE_ATTENDANCE_CONFIG_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_ATTENDANCE_CONFIG_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_ATTENDANCE_CONFIG_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_ALL_ATTENDANCE_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
        attendance: [],
      };

    case GET_ALL_ATTENDANCE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        attendance: payload.items,
      };

    case GET_ALL_ATTENDANCE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case PUNCH_IN_OUT_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
        myAttendance: [],
      };

    case PUNCH_IN_OUT_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        myAttendance: [payload],
      };
    case PUNCH_IN_OUT_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case UPDATE_ATTENDANCE_CONFIG_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
        attendanceConfig: {},
      };
    case UPDATE_ATTENDANCE_CONFIG_SUCCESS:
      console.log("payload", payload);
      return {
        ...state,
        showGlobalLoader: false,
        attendanceConfig: [payload],
      };
    case UPDATE_ATTENDANCE_CONFIG_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    default:
      return state;
  }
}
