import {
  GET_PAYROLL_REQUESTED,
  GET_PAYROLL_SUCCESS,
  GET_PAYROLL_ERRORED,
  GET_PAYROLL_PROFILE_REQUESTED,
  GET_PAYROLL_PROFILE_SUCCESS,
  GET_PAYROLL_PROFILE_ERRORED,
  CREATE_PAYROLL_PROFILE_REQUESTED,
  CREATE_PAYROLL_PROFILE_SUCCESS,
  CREATE_PAYROLL_PROFILE_ERRORED,
  UPDATE_PAYROLL_PROFILE_REQUESTED,
  UPDATE_PAYROLL_PROFILE_SUCCESS,
  UPDATE_PAYROLL_PROFILE_ERRORED,
  CREATE_PAYROLL_INSTANCE_REQUESTED,
  CREATE_PAYROLL_INSTANCE_SUCCESS,
  CREATE_PAYROLL_INSTANCE_ERRORED,
  GET_ALL_PAYROLL_INSTANCE_REQUESTED,
  GET_ALL_PAYROLL_INSTANCE_SUCCESS,
  GET_ALL_PAYROLL_INSTANCE_ERRORED,
  UPDATE_PAYROLL_INSTANCE_REQUESTED,
  UPDATE_PAYROLL_INSTANCE_SUCCESS,
  UPDATE_PAYROLL_INSTANCE_ERRORED,
} from "../constant/action-types";

import axios from "axios";

import { showSnackbar } from "../../../../actions/action-snackbar";

import configureStore from "../../../../store/configureStore";
import { ControlCameraOutlined } from "@material-ui/icons";

const store = configureStore();

export const get_payroll_requested = () => ({
  type: GET_PAYROLL_REQUESTED,
});

export const get_payroll_success = (payload) => ({
  type: GET_PAYROLL_SUCCESS,
  payload,
});

export const get_payroll_errored = (error) => ({
  type: GET_PAYROLL_ERRORED,
  error,
});

export const create_payroll_profile_requested = () => ({
  type: CREATE_PAYROLL_PROFILE_REQUESTED,
});

export const create_payroll_profile_success = (payload) => ({
  type: CREATE_PAYROLL_PROFILE_SUCCESS,
  payload,
});

export const create_payroll_profile_errored = (error) => ({
  type: CREATE_PAYROLL_PROFILE_ERRORED,
  error,
});

export const get_payroll_profile_requested = () => ({
  type: GET_PAYROLL_PROFILE_REQUESTED,
});

export const get_payroll_profile_success = (payload) => ({
  type: GET_PAYROLL_PROFILE_SUCCESS,
  payload,
});

export const get_payroll_profile_errored = (error) => ({
  type: GET_PAYROLL_PROFILE_ERRORED,
  error,
});

export const update_payroll_profile_requested = () => ({
  type: UPDATE_PAYROLL_PROFILE_REQUESTED,
});

export const update_payroll_profile_success = (payload) => ({
  type: UPDATE_PAYROLL_PROFILE_SUCCESS,
  payload,
});

export const update_payroll_profile_errored = (error) => ({
  type: UPDATE_PAYROLL_PROFILE_ERRORED,
  error,
});

export const create_payroll_instance_requested = () => ({
  type: CREATE_PAYROLL_INSTANCE_REQUESTED,
});

export const create_payroll_instance_success = (payload) => ({
  type: CREATE_PAYROLL_INSTANCE_SUCCESS,
  payload,
});

export const create_payroll_instance_errored = (error) => ({
  type: CREATE_PAYROLL_INSTANCE_ERRORED,
  error,
});

export const get_all_payroll_instance_requested = () => ({
  type: GET_ALL_PAYROLL_INSTANCE_REQUESTED,
});

export const get_all_payroll_instance_success = (payload) => ({
  type: GET_ALL_PAYROLL_INSTANCE_SUCCESS,
  payload,
});

export const get_all_payroll_instance_errored = (error) => ({
  type: GET_ALL_PAYROLL_INSTANCE_ERRORED,
  error,
});

export const update_payroll_instance_requested = () => ({
  type: UPDATE_PAYROLL_INSTANCE_REQUESTED,
});

export const update_payroll_instance_success = (payload) => ({
  type: UPDATE_PAYROLL_INSTANCE_SUCCESS,
  payload,
});

export const update_payroll_instance_errored = (error) => ({
  type: UPDATE_PAYROLL_INSTANCE_ERRORED,
  error,
});

export const create_payroll_profile =
  (payload, activeOuid, activeOuidChain) => async (dispatch) => {
    dispatch(create_payroll_profile_requested());
    console.log(`create_attendance_config_requested`);
    const { hrmApiEndpoint, credentials } = store.getState().jwt.api;
    if (hrmApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${hrmApiEndpoint}/payrollprofile`;
      const method = "post";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("Payroll Setup Successfull");
          dispatch(
            showSnackbar("Payroll Setup Successfull", "success")
          );
          dispatch(create_payroll_profile_success([{data: response.data}]));
        })
        .catch((err) => {
          console.log("Payroll profile created Failed");
          dispatch(showSnackbar("Payroll profile create", "error"));
          dispatch(create_payroll_profile_errored(err));
        });
    }
    return null;
  };

export const get_payroll = (activeOuid) => async (dispatch) => {
  dispatch(get_payroll_requested());
  const { hrmApiEndpoint, credentials } = store.getState().jwt.api;
  if (hrmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${hrmApiEndpoint}/payrollprofile`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get payroll Success", response.data);
        dispatch(get_payroll_success(response.data.items));
      })
      .catch((err) => {
        console.log("Get payroll Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get payroll Failed", "error"));
        }
        dispatch(get_payroll_errored(err));
      });
  }
  return null;
};

export const get_payroll_profile = (activeOuid, username) => async (dispatch) => {
  dispatch(get_payroll_profile_requested());
  const { hrmApiEndpoint, credentials } = store.getState().jwt.api;
  if (hrmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${hrmApiEndpoint}/payrollprofile?username=${username}`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get payroll profile Success", response.data.items);
        dispatch(get_payroll_profile_success(response.data.items[0].data));
      })
      .catch((err) => {
        console.log("Get payroll profile Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get payroll profile Failed", "error"));
        }
        dispatch(get_payroll_profile_errored(err));
      });
  }
  return null;
};


export const update_payroll_profile =
  (payload, activeOuid, identifier) => async (dispatch) => {
    dispatch(update_payroll_profile_requested());
    console.log(`update_payroll_profile_requested`);
    const { hrmApiEndpoint, credentials } = store.getState().jwt.api;
    const dataPayload = { data: payload };
    if (hrmApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${hrmApiEndpoint}/payrollprofile/${identifier}`;
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, dataPayload, axiosHeader)
        .then((response) => {
          console.log("Payroll profile updated Successfully");
          dispatch(
            showSnackbar("Payroll profile updated Successfully", "success")
          );
          dispatch(update_payroll_profile_success(dataPayload));
        })
        .catch((err) => {
          console.log("Payroll profile updated Failed", err);
          dispatch(showSnackbar("Payroll profile updated", "error"));
          dispatch(update_payroll_profile_errored(err));
        });
    }
    return null;
  };

export const create_payroll_instance =
  (payload, activeOuid, activeOuidChain) => async (dispatch) => {
    dispatch(create_payroll_instance_requested());
    console.log(`create_payroll_instance_requested`);
    const { hrmApiEndpoint, credentials } = store.getState().jwt.api;
    if (hrmApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${hrmApiEndpoint}/payrollinstance`;
      const method = "post";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("Payroll instance created Successfull");
          dispatch(
            showSnackbar("Payroll instance created", "success")
          );
          dispatch(create_payroll_instance_success([{data: response.data}]));
        })
        .catch((err) => {
          console.log("Payroll instance create Failed");
          dispatch(showSnackbar("Payroll instance create", "error"));
          dispatch(create_payroll_instance_errored(err));
        });
    }
    return null;
  };

export const get_payroll_instance = (activeOuid, query) => async (dispatch) => {
  dispatch(get_all_payroll_instance_requested());
  const { hrmApiEndpoint, credentials } = store.getState().jwt.api;
  if (hrmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${hrmApiEndpoint}/payrollinstance?${query}`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get payroll instance Success", response.data);
        dispatch(get_all_payroll_instance_success(response.data.items[0].data));
      })
      .catch((err) => {
        console.log("Get payroll instance Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get payroll instance Failed", "error"));
        }
        dispatch(get_all_payroll_instance_errored(err));
      });
  }
  return null;
};

export const update_payroll_instance =
  (payload, activeOuid, identifier) => async (dispatch) => {
    dispatch(update_payroll_instance_requested());
    console.log(`update_payroll_profile_requested`);
    const { hrmApiEndpoint, credentials } = store.getState().jwt.api;
    const dataPayload = { data: payload };
    if (hrmApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${hrmApiEndpoint}/payrollinstance/${identifier}`;
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, dataPayload, axiosHeader)
        .then((response) => {
          console.log("Payroll instance updated Successfully");
          dispatch(
            showSnackbar("Payroll instance updated Successfully", "success")
          );
          dispatch(update_payroll_instance_success(dataPayload));
        })
        .catch((err) => {
          console.log("Payroll instance updated Failed", err);
          dispatch(showSnackbar("Payroll instance updated", "error"));
          dispatch(update_payroll_instance_errored(err));
        });
    }
    return null;
  };