import React, { Component } from "react";
import { connect } from "react-redux";
import { history } from "../../../reducers";
import MyCourseCard from "./MyCourseCard";
import configureStore from "../../../store/configureStore";
import SidebarWrapper from "../../layout/SidebarWrapper";
import SearchField from "../../common/SearchField";
import CommonSnackBar from "../../common/CommonSnackBar";
import { setActiveOuid } from "../../../actions/action-permissions";
import { get_my_subc_courses } from "../../../actions/action-courseuser";
import { DataGrid } from "@material-ui/data-grid";
import { timeFormat } from "../../common/CommonFunctions";
import CommonPopUp from "../../common/CommonPopUp";
import Button from "@material-ui/core/Button";
import Loader from "../../common/Loader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CertificationImage from "../../assets/images/ibcImages/certificationbanner.png";
import Banner from "../../assets/images/ibcImages/homebanner.png";
import Business from "../../assets/images/ibcImages/buildings.png";

import { Paper } from "@material-ui/core";

const store = configureStore();

class MyCourseDash extends Component {
  constructor() {
    super();
    this.state = {
      selectionModel: [],
      coursesToShow: [],
    };
  }

  componentDidMount() {
    this.fetchDetails();
  }

  async fetchDetails() {
    const { activeOuid, activeOuidChain } = store.getState().permission;
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    console.log("path : ", path);
    const { setActiveOuid } = this.props;
    await setActiveOuid(ouid);
    this.setState({
      coursesToShow: [],
    });
    const { get_my_subc_courses } = this.props;
    await get_my_subc_courses(activeOuid, activeOuidChain);
    console.log(
      "get_my_subc_courses store.getState().courseuser ",
      store.getState().courseuser
    );
    this.setState({
      coursesToShow: store.getState().courseuser.courseUsers,
    });
  }

  componentWillUnmount() {}

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  setSelectionModel(selection) {
    this.setState({
      selectionModel: selection,
    });
  }

  render() {
    const { permissions } = store.getState().permission;
    const { showGlobalLoader } = store.getState().courseuser;
    const { activeOuid, activeOuidChain } = store.getState().permission;
    console.log("showGlobalLoader in coursedash render", showGlobalLoader);
    return (
      <div className="page">
        <SidebarWrapper auth={this.props.auth} permissions={permissions} />
        <section className="section">
          <div className="container">
            <div className="">
              <div className="PageBannerWrapper">
                <div>
                  <Paper className="PageBanner">
                    <div>
                      <h1>My courses</h1>
                      <p>
                        Courses you have subscribed
                      </p>
                    </div>
                  </Paper>
                </div>
              </div>
              <div className="">
                {showGlobalLoader ? (
                  <Loader />
                ) : (
                  <div className="CourseWrapperflex">
                    {this.state.coursesToShow.length !== 0
                      ? this.renderPosts(this.state.coursesToShow)
                      : "No Courses found..."}
                  </div>
                )}
              </div>
              <CommonSnackBar />
            </div>
          </div>
        </section>
      </div>
    );
  }

  renderPosts(PostList) {
    console.log("PostList : ", JSON.stringify(PostList));
    console.log(
      "this.state.coursesToShow.length : ",
      this.state.coursesToShow.length
    );

    return PostList.map((item) => (
      <MyCourseCard key={item.data.course} Post={item} />
    ));
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  posts: state.post,
});

const mapDispatchToProps = (dispatch) => ({
  get_my_subc_courses: (activeOuid, activeOuidChain) =>
    dispatch(get_my_subc_courses(activeOuid, activeOuidChain)),
  setActiveOuid: (activeOuid) => dispatch(setActiveOuid(activeOuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyCourseDash);
