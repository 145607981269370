import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderInner from "./HeaderInner";
import configureStore from "../../../store/configureStore";
import Loader from "../../common/Loader";
import "./App.css";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import { Button } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useEffect } from "react";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  get_attendance_config,
  create_attendance_config,
  update_attendance_config,
} from "./actions/action-attendance";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 15,
  },
  input: {
    marginBottom: 10,
  },
}));

export default function AttendanceSetup() {
  const classes = useStyles();
  const dispatch = useDispatch();
  var configId;

  const initialState = {
    config: {
      in: null,
      out: null,
      gracePeriod: 30,
      minWorkDuration: 360,
      type: "attendance",
    },
  };

  const [configState, setState] = useState(initialState);

  const { attendanceConfig, showGlobalLoader } = useSelector(
    (state) => state.attendance
  );

  const { activeOuid } = store.getState().permission;

  const handleInTimeChange = (inTime) => {
    console.log("handleInTimeChange", inTime);
    setState({
      config: {
        ...configState.config,
        in: inTime,
      },
    });
    console.log("configState", configState);
  };

  const handleOutTimeChange = (outTime) => {
    console.log("handleOutTimeChange", outTime);
    setState({
      config: {
        ...configState.config,
        out: outTime,
      },
    });
  };

  const handleGracePeriodChange = (gracePeriod) => {
    console.log("handleGracePeriodChange", gracePeriod.currentTarget.value);
    setState({
      config: {
        ...configState.config,
        gracePeriod: gracePeriod.currentTarget.value,
      },
    });
  };

  const handleMinWorkDurationChange = (minWorkDuration) => {
    console.log(
      "handleMinWorkDurationChange",
      minWorkDuration.currentTarget.value
    );

    setState({
      config: {
        ...configState.config,
        minWorkDuration: minWorkDuration.currentTarget.value,
      },
    });
  };

  const handleSubmit = () => {
    console.log("handleSubmit", configState.config);
    console.log(
      " store.getState().attendance.attendanceConfig",
      store.getState().attendance.attendanceConfig
    );

    console.log(
      "configId",
      store.getState().attendance.attendanceConfig[0].data.identifier
    );
    dispatch(
      update_attendance_config(
        configState.config,
        activeOuid,
        store.getState().attendance.attendanceConfig[0].data.identifier
      )
    );
  };

  const handleCreate = () => {
    console.log("handleCreate", configState.config);
    dispatch(create_attendance_config(configState.config, activeOuid));
  };

  const fetchData = async (dispatch) => {
    await dispatch(get_attendance_config(activeOuid));
    console.log("get_attendance_config done");
    console.log("store.getState().attendance", store.getState().attendance);
    if (store.getState().attendance.attendanceConfig > 0) {
      console.log("attendanceConfig.length > 0");

      setState({
        config: store.getState().attendance.attendanceConfig[0].data,
      });
      console.log("setState done");
    }
  };

  useEffect(() => {
    async function fetchData(dispatch) {
      await dispatch(get_attendance_config(activeOuid));
      console.log("get_attendance_config done");
      console.log(
        "store.getState().attendance.attendanceConfig",
        store.getState().attendance.attendanceConfig
      );

      console.log("attendanceConfig", attendanceConfig);

      if (store.getState().attendance.attendanceConfig.length > 0) {
        console.log("attendanceConfig.length > 0");

        setState({
          config: store.getState().attendance.attendanceConfig[0].data,
        });
        console.log("setState done");
      }
    }
    fetchData(dispatch);
  }, []);

  const renderAttendanceConfig = () => {
    return (
      <form className={classes.root} autoComplete="off">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardTimePicker
            margin="normal"
            id="intime"
            label="In Time"
            value={configState.config.in}
            onChange={handleInTimeChange}
            KeyboardButtonProps={{
              "aria-label": "change time",
            }}
            fullWidth
            inputVariant="outlined"
          />
          <KeyboardTimePicker
            margin="normal"
            id="outtime"
            label="Out Time"
            value={configState.config.out}
            onChange={handleOutTimeChange}
            KeyboardButtonProps={{
              "aria-label": "change time",
            }}
            fullWidth
            inputVariant="outlined"
          />
        </MuiPickersUtilsProvider>
        <TextField
          id="graceperiod"
          label="Grace Period"
          value={configState.config.gracePeriod}
          fullWidth
          variant="outlined"
          className={classes.input}
          type="number"
          onChange={handleGracePeriodChange}
        />
        <TextField
          id="minworkduration"
          type="number"
          value={configState.config.minWorkDuration}
          label="Min Work Duration"
          fullWidth
          variant="outlined"
          onChange={handleMinWorkDurationChange}
          className={classes.input}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
        >
          Save
        </Button>
      </form>
    );
  };

  const renderCreateAttendanceConfig = () => {
    return (
      <form className={classes.root} autoComplete="off">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardTimePicker
            margin="normal"
            id="intime1"
            label="In Time"
            value={configState.config.in}
            onChange={handleInTimeChange}
            KeyboardButtonProps={{
              "aria-label": "change time",
            }}
            fullWidth
            inputVariant="outlined"
          />
          <KeyboardTimePicker
            margin="normal"
            id="outtime1"
            label="Out Time"
            value={configState.config.out}
            onChange={handleOutTimeChange}
            KeyboardButtonProps={{
              "aria-label": "change time",
            }}
            fullWidth
            inputVariant="outlined"
          />
        </MuiPickersUtilsProvider>
        <TextField
          id="graceperiod1"
          name="graceperiod1"
          label="Grace Period"
          fullWidth
          variant="outlined"
          value={configState.config.gracePeriod}
          className={classes.input}
          onChange={handleGracePeriodChange}
        />
        <TextField
          id="minworkduration1"
          name="minworkduration1"
          label="Min Work Duration"
          value={configState.config.minWorkDuration}
          fullWidth
          variant="outlined"
          onChange={handleMinWorkDurationChange}
          className={classes.input}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => handleCreate()}
        >
          Create
        </Button>
      </form>
    );
  };

  return (
    <>
      <HeaderInner pageTitle="Attendance Setup" />
      {showGlobalLoader ? (
        <Loader />
      ) : (
        <div className="container--narrow HrmAppPage">
          {attendanceConfig.length !== 0
            ? renderAttendanceConfig()
            : renderCreateAttendanceConfig()}
        </div>
      )}
    </>
  );
}
