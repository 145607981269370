import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { history } from "../../../../reducers";
import avatar from "../images/Frame.svg";
import { Button, TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { update_leave } from "../actions/action-leave";
import configureStore from "../../../../store/configureStore";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  rejectionButtonWrapper: {
    marginTop: 10,
    display: "flex",
    gap: 15,
  },
  buttonGreen: {
    backgroundColor: theme.mycolor.green.main,
    "&:hover": {
      backgroundColor: theme.mycolor.green.light,
    },
  },
  buttonRed: {
    backgroundColor: theme.mycolor.red.main,
    "&:hover": {
      backgroundColor: theme.mycolor.red.light,
    },
  },
}));

export default function LeaveCard(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rejection, setRejection] = React.useState(false);
  const { activeOuid } = store.getState().permission;
  const [state, setState] = React.useState({
    rejectionComment: "",
  });

  const leave = props.item;
  console.log("props", leave);

  function getDifferenceInDays(startDate, endDate) {
    console.log("startDate", startDate);
    console.log("endDate", endDate);
    console.log("startHalf", props.item.startHalf);
    console.log("endHalf", props.item.endHalf);

    const [yearSt, monthSt, daySt] = startDate.split("-");
    const sDate = new Date(yearSt, monthSt - 1, daySt);

    const [yearEnd, monthEnd, dayEnd] = endDate.split("-");
    const eDate = new Date(yearEnd, monthEnd - 1, dayEnd);

    return getBusinessDatesCount(sDate, eDate);
  }

  const getBusinessDatesCount = (startDate, endDate) => {
    let count = 0;
    let curDate = +startDate;
    while (curDate <= +endDate) {
      const dayOfWeek = new Date(curDate).getDay();
      const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;
      if (!isWeekend) {

        
        count++;
      }
      curDate = curDate + 24 * 60 * 60 * 1000;
    }
    return count;
  };

  function handleLeaveApproval() {
    leave.status = "approved";
    dispatch(update_leave(activeOuid, leave, leave.identifier));
  }
  function handleLeaveReject() {
    leave.rejectionComment = state.rejectionComment;
    leave.status = "rejected";
    dispatch(update_leave(activeOuid, leave, leave.identifier));
  }
  
  function handleRejctionUi() {
    setRejection(true);
  }
  const handleChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value });
    console.log("State", state);
  };
  return (
    <div className="leaveListPendingCard">
      <div className="leaveListPendingCardHeader">
        <div className="leaveListPendingCardProfile">
          <img src={avatar} />
          <div>
            <div className="leaveListPendingCardProfileName">
              {props.item.name}
            </div>
            {/* <div className="leaveListPendingCardProfileDesignation">8138858555</div> */}
          </div>
        </div>
        <div className="leaveListPendingCardLeavetype">
          {props.item.leaveType}
        </div>
      </div>
      <div className="leaveListPendingCardBody">
        <div>
          <div className="InfoLabel">From</div>
          <div className="InfoData">{props.item.startDate}</div>
        </div>
        <div>
          <div className="InfoLabel"> To</div>
          <div className="InfoData">{props.item.endDate}</div>
        </div>
        <div>
          <div className="InfoLabel">Days</div>
          <div className="InfoData">
            {getDifferenceInDays(props.item.startDate, props.item.endDate)}
          </div>
        </div>
      </div>
      <div className="leaveListPendingCardFooter">
        <div className="InfoLabel">Purpose</div>
        <div className="InfoData">{props.item.requestComment}</div>
      </div>
      {rejection ? (
        <div>
          <h4>Reject Leave Request</h4>
          <TextField
            fullWidth
            id="rejectionComment"
            label="Reason for rejection"
            multiline
            rows={4}
            onChange={handleChange}
            variant="outlined"
          />
          <div className={classes.rejectionButtonWrapper}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => handleLeaveReject()}
              className={classes.buttonRed}
            >
              Reject
            </Button>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={() => setRejection(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <div className="leaveListPendingCardButtonWrap">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => handleLeaveApproval()}
            className={classes.buttonGreen}
          >
            APPROVE
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => handleRejctionUi()}
            className={classes.buttonRed}
          >
            REJECT
          </Button>
        </div>
      )}
    </div>
  );
}
