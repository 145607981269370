import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderInner from "./HeaderInner";
import configureStore from "../../../store/configureStore";
import FormControl from "@material-ui/core/FormControl";
import "./App.css";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { history } from "../../../reducers";
import CommonSnackBar from "../../common/CommonSnackBar";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { apply_leave } from "./actions/action-leave";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 15,
  },
  input: {
    marginBottom: 10,
  },
}));

export default function ApplyLeave() {
  const dispatch = useDispatch();
  const { activeOuid } = useSelector((state) => state.permission);
  const { details } = store.getState().myProfile;

  const [state, setState] = React.useState({
    username: details.data.username,
    startDate: "",
    endDate: "",
    startHalf: "AM",
    endHalf: "PM",
    leaveType: "Earned",
    requestComment: "",
    rejectionComment: "",
  });

  const classes = useStyles();

  const handleChange = (event) => {
    console.log("event.target.name", event.target.name);
    console.log("event.target.value", event.target.value);
    console.log("event.target.id", event.target.id);
    console.log("event", event);
    if (event.target.id) {
      setState({ ...state, [event.target.id]: event.target.value });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };
  const handleSubmit = () => {
    console.log("addStaffHandleClick", state);
    dispatch(apply_leave(state, activeOuid));
    history.push(`/${activeOuid}/leave`);
  };

  return (
    <div className={classes.root}>
      <HeaderInner />
      <div className="container--narrow">
        <h1>Apply Leave</h1>
        <form autoComplete="off">
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                id="startDate"
                label="From Date"
                variant="outlined"
                type="date"
                className={classes.textField}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl
                variant="outlined"
                fullWidth
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Half
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="startHalf"
                  name="startHalf"
                  value={state.startHalf}
                  onChange={handleChange}
                  label="Half"
                >
                  <MenuItem value={"AM"}>First Half</MenuItem>
                  <MenuItem value={"PM"}>Second Half</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="endDate"
                label="To Date"
                variant="outlined"
                type="date"
                defaultValue=""
                onChange={handleChange}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl
                variant="outlined"
                fullWidth
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Half
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="endHalf"
                  name="endHalf"
                  onChange={handleChange}
                  value={state.endHalf}
                  label="Half"
                >
                  <MenuItem value={"AM"}>First Half</MenuItem>
                  <MenuItem value={"PM"}>Second Half</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Leave type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  value={state.leaveType}
                  id="leaveType"
                  name="leaveType"
                  onChange={handleChange}
                  label="Leave type"
                >
                  <MenuItem value={"Sick"}>Sick</MenuItem>
                  <MenuItem value={"Casual"}>Casual</MenuItem>
                  <MenuItem value={"Earned"}>Earned</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="requestComment"
                label="Reason"
                multiline
                rows={4}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={6}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={() => handleSubmit(state)}
              >
                Apply
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => {
                  history.push(`/`);
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
      <CommonSnackBar />
    </div>
  );
}
