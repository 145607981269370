import {
  MARK_ATTENDANCE_REQUESTED,
  MARK_ATTENDANCE_SUCCESS,
  MARK_ATTENDANCE_ERRORED,
  GET_ALL_ATTENDANCE_REQUESTED,
  GET_ALL_ATTENDANCE_SUCCESS,
  GET_ALL_ATTENDANCE_ERRORED,
  GET_MY_ATTENDANCE_REQUESTED,
  GET_MY_ATTENDANCE_SUCCESS,
  GET_MY_ATTENDANCE_ERRORED,
  PUNCH_IN_OUT_REQUESTED,
  PUNCH_IN_OUT_SUCCESS,
  PUNCH_IN_OUT_ERRORED,
  UPDATE_ATTENDANCE_CONFIG_REQUESTED,
  UPDATE_ATTENDANCE_CONFIG_SUCCESS,
  UPDATE_ATTENDANCE_CONFIG_ERRORED,
  CREATE_ATTENDANCE_CONFIG_REQUESTED,
  CREATE_ATTENDANCE_CONFIG_SUCCESS,
  CREATE_ATTENDANCE_CONFIG_ERRORED,
  GET_ATTENDANCE_CONFIG_REQUESTED,
  GET_ATTENDANCE_CONFIG_SUCCESS,
  GET_ATTENDANCE_CONFIG_ERRORED,
} from "../constant/action-types";

import axios from "axios";

import { showSnackbar } from "../../../../actions/action-snackbar";

import configureStore from "../../../../store/configureStore";
import { ControlCameraOutlined } from "@material-ui/icons";

const store = configureStore();

export const mark_attendance_requested = () => ({
  type: MARK_ATTENDANCE_REQUESTED,
});

export const mark_attendance_success = (payload) => ({
  type: MARK_ATTENDANCE_SUCCESS,
});

export const mark_attendance_errored = (error) => ({
  type: MARK_ATTENDANCE_ERRORED,
  error,
});

export const get_my_attendance_requested = () => ({
  type: GET_MY_ATTENDANCE_REQUESTED,
});

export const get_my_attendance_success = (payload) => ({
  type: GET_MY_ATTENDANCE_SUCCESS,
  payload,
});

export const get_my_attendance_errored = (error) => ({
  type: GET_MY_ATTENDANCE_ERRORED,
  error,
});

export const get_all_attendance_requested = () => ({
  type: GET_ALL_ATTENDANCE_REQUESTED,
});

export const get_all_attendance_success = (payload) => ({
  type: GET_ALL_ATTENDANCE_SUCCESS,
  payload,
});

export const get_all_attendance_errored = (error) => ({
  type: GET_ALL_ATTENDANCE_ERRORED,
  error,
});

export const punch_in_out_requested = () => ({
  type: PUNCH_IN_OUT_REQUESTED,
});

export const punch_in_out_success = (payload) => ({
  type: PUNCH_IN_OUT_SUCCESS,
  payload,
});

export const punch_in_out_errored = (error) => ({
  type: PUNCH_IN_OUT_ERRORED,
  error,
});

export const update_attendance_config_requested = () => ({
  type: UPDATE_ATTENDANCE_CONFIG_REQUESTED,
});

export const update_attendance_config_success = (payload) => ({
  type: UPDATE_ATTENDANCE_CONFIG_SUCCESS,
  payload,
});

export const update_attendance_config_errored = (error) => ({
  type: UPDATE_ATTENDANCE_CONFIG_ERRORED,
  error,
});

export const create_attendance_config_requested = () => ({
  type: CREATE_ATTENDANCE_CONFIG_REQUESTED,
});

export const create_attendance_config_success = (payload) => ({
  type: CREATE_ATTENDANCE_CONFIG_SUCCESS,
  payload,
});

export const create_attendance_config_errored = (error) => ({
  type: CREATE_ATTENDANCE_CONFIG_ERRORED,
  error,
});

export const get_attendance_config_requested = () => ({
  type: GET_ATTENDANCE_CONFIG_REQUESTED,
});

export const get_attendance_config_success = (payload) => ({
  type: GET_ATTENDANCE_CONFIG_SUCCESS,
  payload,
});

export const get_attendance_config_errored = (error) => ({
  type: GET_ATTENDANCE_CONFIG_ERRORED,
  error,
});

export const mark_attendance =
  (payload, activeOuid, activeOuidChain) => async (dispatch) => {
    dispatch(mark_attendance_requested());
    console.log(`mark_attendance_requested`);
    const { hrmApiEndpoint, credentials } = store.getState().jwt.api;
    if (hrmApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${hrmApiEndpoint}/attendance`;
      const method = "post";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("Attendance mark Successfully");
          dispatch(showSnackbar("Attendance marked Successfully", "success"));
          dispatch(mark_attendance_success({items: [{data: response}]}));
          console.log("{items: [{data: response}]}", {items: [{data: response.data}]});
        })
        .catch((err) => {
          console.log("Attendance mark Failed");
          dispatch(showSnackbar("Attendance mark Failed", "error"));
          dispatch(mark_attendance_errored(err));
        });
    }
    return null;
  };

export const get_all_attendance = (activeOuid, query) => async (dispatch) => {
  dispatch(get_all_attendance_requested());
  console.log(`get_all_STAFF`);
  console.log(`hrmApiEndpoint`);

  const { hrmApiEndpoint, credentials } = store.getState().jwt.api;
  if (hrmApiEndpoint && credentials) {
    console.log(`hrmApiEndpoint`);

    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${hrmApiEndpoint}/attendance?${query}`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get All attendance Success");
        dispatch(get_all_attendance_success(response.data));
      })
      .catch((err) => {
        console.log("Get All attendance Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get All attendance Failed", "error"));
        }
        dispatch(get_all_attendance_errored(err));
      });
  }
  return null;
};

export const get_my_attendance = (activeOuid, query) => async (dispatch) => {
  dispatch(get_my_attendance_requested());
  console.log(`query`, query);
  const { hrmApiEndpoint, credentials } = store.getState().jwt.api;
  if (hrmApiEndpoint && credentials) {
    console.log(`hrmApiEndpoint`);

    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${hrmApiEndpoint}/attendance?${query}`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get My attendance Success", response.data.items);
        dispatch(get_my_attendance_success(response.data.items));
      })
      .catch((err) => {
        console.log("Get My attendance Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get My attendance Failed", "error"));
        }
        dispatch(get_my_attendance_errored(err));
      });
  }
  return null;
};

export const punch_in_out =
  (payload, attendanceid, activeOuid, activeOuidChain) => async (dispatch) => {
    dispatch(punch_in_out_requested());
    console.log(`punch_in_out_requested`);
    console.log(`payload`, payload);
    console.log(`attendanceid`, attendanceid);
    const { hrmApiEndpoint, credentials } = store.getState().jwt.api;
    if (hrmApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${hrmApiEndpoint}/attendance/${attendanceid}`;
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("punch_Successfully", response);
          dispatch(showSnackbar("Punched Successfully", "success"));
          dispatch(punch_in_out_success(response.data));
        })
        .catch((err) => {
          console.log("Punch Failed");
          dispatch(showSnackbar("Punch Failed", "error"));
          dispatch(punch_in_out_errored(err));
        });
    }
    return null;
  };

export const get_attendance_config = (activeOuid) => async (dispatch) => {
  dispatch(get_attendance_config_requested());
  const { hrmApiEndpoint, credentials } = store.getState().jwt.api;
  if (hrmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${hrmApiEndpoint}/config?type=attendance`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("get_attendance_config Success", response.data.items);
        dispatch(get_attendance_config_success(response.data.items));
      })
      .catch((err) => {
        console.log("get_attendance_config Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("get_attendance_config Failed", "error"));
        }
        dispatch(get_attendance_config_errored(err));
      });
  }
  return null;
};

export const update_attendance_config =
  (payload, activeOuid, configId) => async (dispatch) => {
    dispatch(update_attendance_config_requested());
    console.log(`update_attendance_config_requested`);
    const { hrmApiEndpoint, credentials } = store.getState().jwt.api;
    const dataPayload = { data: payload };
    if (hrmApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${hrmApiEndpoint}/config/${configId}`;
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, dataPayload, axiosHeader)
        .then((response) => {
          console.log("Attendance config updated Successfully");
          dispatch(
            showSnackbar("Attendance config updated Successfully", "success")
          );
          dispatch(update_attendance_config_success(dataPayload));
        })
        .catch((err) => {
          console.log("Attendance config updated Failed", err);
          dispatch(showSnackbar("Attendance config updated", "error"));
          dispatch(update_attendance_config_errored(err));
        });
    }
    return null;
  };

export const create_attendance_config =
  (payload, activeOuid, activeOuidChain) => async (dispatch) => {
    dispatch(create_attendance_config_requested());
    console.log(`create_attendance_config_requested`);
    const { hrmApiEndpoint, credentials } = store.getState().jwt.api;
    if (hrmApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${hrmApiEndpoint}/config`;
      const method = "post";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("Attendance config updated Successfully");
          dispatch(
            showSnackbar("Attendance config updated Successfully", "success")
          );
          dispatch(create_attendance_config_success());
        })
        .catch((err) => {
          console.log("Attendance config updated Failed");
          dispatch(showSnackbar("Attendance config updated", "error"));
          dispatch(create_attendance_config_errored(err));
        });
    }
    return null;
  };
