import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";

import HeaderInner from "./HeaderInner";
import configureStore from "../../../store/configureStore";
import "./App.css";
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from "@material-ui/core";
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { register_staff } from "./actions/action-staff";
import { history } from "../../../reducers";
import { is } from "immutable";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 10,
    },
    input: {
        marginBottom:10,
    }
}));


export default function  AddStaff(){
    const classes = useStyles();
    const dispatch = useDispatch();

    const [state, setState] = React.useState({
        appRole: "hrm=user",
        name: "",
        phone_number: "",
        username: "",
        jobTitle: "",
      });

    const { activeOuid } = useSelector((state) => state.permission);

    const handleCheck = (event) => {
        // setChecked(event.target.checked);
        console.log("[event.target.checked]", event.target.checked);
        if(event.target.checked){
            console.log("Before set state", state);
            setState({ ...state, "appRole": "hrm=admin" });
            console.log("After set state", state);
        }
        else{
            setState({ ...state, "appRole": "hrm=user" });
        }
        console.log("state", state);
    };
  
    const handleChange = (event) => {
        setState({ ...state, [event.target.id]: event.target.value });
      };    

    const handleSubmit = () => {
        console.log("addStaffHandleClick", state);
        dispatch(register_staff(state, activeOuid));
        history.push(`/${activeOuid}/staff`);
    }

    return (
        <>
            <HeaderInner />
            <div className="container--narrow">
                
                <form className={classes.root} autoComplete="off">
                    <h1>Add Employees</h1>
                    <TextField 
                        id="name" 
                        label="Name" 
                        fullWidth 
                        variant="outlined" 
                        className={classes.input}
                        onChange={handleChange}
                        />
                    <TextField 
                        id="phone_number" 
                        label="Phone Number" 
                        fullWidth 
                        variant="outlined"
                        className={classes.input}
                        onChange={handleChange}/>
                    <TextField 
                        id="username" 
                        label="Email" 
                        fullWidth 
                        variant="outlined" 
                        className={classes.input}
                        onChange={handleChange}
                        />
                    <TextField 
                        id="jobTitle" 
                        label="Job Title" 
                        fullWidth 
                        variant="outlined" 
                        className={classes.input}
                        onChange={handleChange}
                        />
                    <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                            onChange={handleCheck}
                            id="isAdmin"
                            name="checked"
                            color="primary"
                            />
                        }
                        label="Admin"
                    />
                    <div><p>Select if the user is an administrator</p></div>
                    </FormGroup>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit()}
                        >
                        Save
                    </Button>
                </form>
                
            </div>
            
        </>
    );
}
