import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { history } from '../../../../reducers';
import { useDispatch } from 'react-redux';
import { delete_staff } from '../actions/action-staff';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      margin: '0 auto',
    },
    avatar: {
        textTransform: "uppercase",
    }
  }));
let colorHolder = [
    // "#F7941D","#940A73", #1976d2,
    "#214B87",
    // "#34785C", "#F71D1D", "#8F00B5", "#E01E5A", "#36C5F0"
    ];

export default function  StaffCard(props){
    const classes = useStyles();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const { activeOuid } = useSelector((state) => state.permission);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleDetele = () => {
        console.log("props.item.username delete", props.item.username)
        dispatch(delete_staff(activeOuid, props.item.username));
    }
    return (
        <>
            <ListItem
                onClick={() => history.push(`/${activeOuid}/attendancelog/${props.item.username}`)}
                >
                <ListItemAvatar>
                    <Avatar 
                        className={classes.avatar} 
                        style={{
                            backgroundColor: colorHolder[Math.floor(Math.random() * 1)]
                        }}>
                        {props.item.name.charAt(0)}
                    </Avatar>
                    
                </ListItemAvatar>
                <ListItemText
                    primary={props.item.name}
                    secondary={props.item.phone_number}
                />
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={handleClick}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => window.open(`tel:${props.item.phone_number}`)}>
                            Call
                        </MenuItem>
                        <MenuItem onClick={() => window.open(`mailto:${props.item.email}`)}>
                            Email
                        </MenuItem>
                        <MenuItem onClick={() => history.push(`/edwin/editstaff/${props.item.username}`)}>
                            Edit
                        </MenuItem>
                        <MenuItem onClick={() => handleDetele()}>
                            Detete
                        </MenuItem>
                    </Menu>
                </ListItemSecondaryAction>
                </ListItem>
        </>
      
    );
}
