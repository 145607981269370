import {
  CREATE_COURSEUSER_REQUESTED,
  CREATE_COURSEUSER_SUCCESS,
  CREATE_COURSEUSER_ERRORED,
  GET_COURSEUSERS_REQUESTED,
  GET_COURSEUSERS_SUCCESS,
  GET_COURSEUSERS_ERRORED,
  DELETE_COURSEUSER_REQUESTED,
  DELETE_COURSEUSER_SUCCESS,
  DELETE_COURSEUSER_ERRORED,
  GET_COURSEUSER_ERRORED,
  GET_COURSEUSER_REQUESTED,
  GET_COURSEUSER_SUCCESS,
} from "../constant/action-types";
import axios from "axios";
import { showSnackbar } from "./action-snackbar";

import configureStore from "../store/configureStore";

const store = configureStore();

export const create_courseuser_requested = () => ({
  type: CREATE_COURSEUSER_REQUESTED,
});

export const create_courseuser_success = () => ({
  type: CREATE_COURSEUSER_SUCCESS,
});

export const create_courseuser_errored = (error) => ({
  type: CREATE_COURSEUSER_ERRORED,
  error,
});

export const create_course_user = (payload) => async (dispatch) => {
  console.log(`create_course_user`);

  dispatch(create_courseuser_requested());
  const path = window.location.pathname.split("/");
  const activeOuid = path[1];
  const { activeOuidChain } = store.getState().permission;
  console.log(`create_course_user`);
  const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
  if (coursesApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    var url = `${coursesApiEndpoint}/courseuser`;
    if (payload.bookInParent) {
      url = `${coursesApiEndpoint}/courseuser`;
    }
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
        "x-ouidchain": activeOuidChain,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        dispatch(showSnackbar("Courseuser created Successfully", "success"));
        dispatch(create_courseuser_success());
      })
      .catch((err) => {
        console.log("Courseuser creation Failed");
        dispatch(showSnackbar("Courseuser creation Failed", "error"));
        dispatch(create_courseuser_errored(err));
      });
  }
  return null;
};

export const get_courseusers_requested = () => ({
  type: GET_COURSEUSERS_REQUESTED,
});

export const get_courseusers_success = (payload) => ({
  type: GET_COURSEUSERS_SUCCESS,
  payload,
});

export const get_courseusers_errored = (error) => ({
  type: GET_COURSEUSERS_ERRORED,
  error,
});

export const get_course_users =
  (activeOuid, activeOuidChain, category, ouid) => async (dispatch) => {
    dispatch(get_courseusers_requested());
    console.log(`get_course_users`);
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${coursesApiEndpoint}/courseuser`;
      if (!category === "") {
        url = `${coursesApiEndpoint}/courseuser`;
      }

      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get All Courses Fetched Successfully");
          dispatch(get_courseusers_success(response.data));
        })
        .catch((err) => {
          console.log("Get All Courseusers Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get All Courseusers Failed", "error"));
          }
          dispatch(get_courseusers_errored(err));
        });
    }
    return null;
  };

export const get_all_course_users =
  (activeOuid, activeOuidChain) => async (dispatch) => {
    dispatch(get_courseusers_requested());
    console.log(`get_course_users`);
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${coursesApiEndpoint}/courseuser?getAll=true`;
      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get All Courseusers Fetched Successfully");
          dispatch(get_courseusers_success(response.data));
        })
        .catch((err) => {
          console.log("Get All Courseusers Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get All Courseusers Failed", "error"));
          }
          dispatch(get_courseusers_errored(err));
        });
    }
    return null;
  };

export const get_my_subc_courses =
  (activeOuid, activeOuidChain) => async (dispatch) => {
    dispatch(get_courseusers_requested());
    console.log(`get_course_users`);
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${coursesApiEndpoint}/courseuser?myCourses=true`;
      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get All Courseusers Fetched Successfully");
          dispatch(get_courseusers_success(response.data));
        })
        .catch((err) => {
          console.log("Get All Courseusers Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get All Courseusers Failed", "error"));
          }
          dispatch(get_courseusers_errored(err));
        });
    }
    return null;
  };

export const get_course_user =
  (activeOuid, activeOuidChain, id) => async (dispatch) => {
    dispatch(get_courseuser_requested());
    console.log(`get_course`);
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${coursesApiEndpoint}/course/${id}`;
      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get post Fetched Successfully", response.data);
          dispatch(get_courseuser_success(response.data));
        })
        .catch((err) => {
          console.log("Get Course Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get All Courses By Failed", "error"));
          }
          dispatch(get_courseuser_errored(err));
        });
    }
    return null;
  };

export const get_courseuser_requested = () => ({
  type: GET_COURSEUSER_REQUESTED,
});

export const get_courseuser_success = (payload) => ({
  type: GET_COURSEUSER_SUCCESS,
  payload,
});

export const get_courseuser_errored = (error) => ({
  type: GET_COURSEUSER_ERRORED,
  error,
});

export const delete_course_user =
  (activeOuid, activeOuidChain, id) => async (dispatch) => {
    dispatch(delete_courseuser_requested());
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${coursesApiEndpoint}/course/${id}`;
      const { activeOuidCoreConfigs } = store.getState().config;
      if (activeOuidCoreConfigs.data) {
        if (activeOuidCoreConfigs.data.config.entityRules) {
          const entityRules = activeOuidCoreConfigs.data.config.entityRules;
          const enitityRule = entityRules.find(
            ({ entity }) => entity === "course"
          );
          if (enitityRule) {
            const actionsData = enitityRule.publishToApiTopic.actions;
            if (actionsData.indexOf("D") > -1) {
              url = `${coursesApiEndpoint}/course/${id}?publishToApiTopic=true`;
            }
          }
        }
      }
      const method = "delete";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Course Deleted Successfully");
          dispatch(delete_courseuser_success(response.data));
        })
        .catch((err) => {
          console.log("Delete Course  Failed");
          dispatch(showSnackbar("Delete Course Failed", "error"));
          dispatch(delete_courseuser_errored(err));
        });
    }
    return null;
  };

export const delete_courseuser_requested = () => ({
  type: DELETE_COURSEUSER_REQUESTED,
});

export const delete_courseuser_success = (payload) => ({
  type: DELETE_COURSEUSER_SUCCESS,
  payload,
});

export const delete_courseuser_errored = (error) => ({
  type: DELETE_COURSEUSER_ERRORED,
  error,
});
