import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { get_all_attendance } from "./actions/action-attendance";
import { get_all_staff } from "./actions/action-staff";

import configureStore from "../../../store/configureStore";
import "./App.css";
import Loader from "../../common/Loader";
import DatePicker from 'sassy-datepicker';
import { history } from "../../../reducers";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import HeaderInner from "./HeaderInner";
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import StaffAttendanceCard from './common/StaffAttendanceCard';
import CommonSnackBar from "../../common/CommonSnackBar";
import InOutSwitch from "./common/InOutSwitch";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    margin: '0 auto',
  },
  attendanceButtonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom:10,
  },
  buttonGreen: {
    backgroundColor: theme.mycolor.green.main,
    '&:hover': {
      backgroundColor: theme.mycolor.green.light,
    },
  },
  buttonRed: {
    backgroundColor: theme.mycolor.red.main,
    '&:hover': {
      backgroundColor: theme.mycolor.red.light,
    },
  },
  attendanceButtonInliner:{
    display: "flex",
    justifyContent: 'center',
    gap: 10
  }
}));

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("");
}

export default function  Attendance(){
  const classes = useStyles();
  const [date, setDate] = useState(new Date());

  const { activeOuid } = store.getState().permission;
  const {staffs} = useSelector((state) => state.staffs);
  const {attendance, showGlobalLoader} = useSelector((state) => state.attendance);
  const dispatch = useDispatch();

  const fetchStaffData = async (dispatch) => {
    await dispatch(get_all_staff(activeOuid));
    // console.log("fetchStaffData > store > staffs", store.getState().staffs);
  };
  
  const fetchAttendanceData = async (dispatch, query) => {
    await dispatch(get_all_attendance(activeOuid, query));
    // console.log("fetchAttendanceData > store > attendace", store.getState().attendance.attendance);
  };

  const onChange = newDate => {
    setDate(newDate);
    const converted = convert(newDate);
    const queryOnchange = `date=${converted}`;
    fetchAttendanceData(dispatch, queryOnchange);
  };

  const renderStaffList = (staffListToShow, attendance) => {
    console.log("Render Staff List for attendance *********", staffListToShow, attendance);
    if(attendance.length === 0){
      console.log("attendance.length", attendance.length);
      for (let index = 0; index < staffListToShow.length; index++) {
        const staffitem = staffListToShow[index];
        console.log("staffListToShow[index]", staffListToShow[index])
        staffitem.data["attendanceStatusCode"] = "U";
      }
    }
    else{
      console.log("Else part", attendance.length);
      for (let index = 0; index < attendance.length; index++) {
        const attendanceitem = attendance[index];
        for (let index = 0; index < staffListToShow.length; index++) {
          const staffitem = staffListToShow[index];
          if(attendanceitem.data.username === staffitem.data.username){
            staffitem.data["attendanceStatusCode"] = attendanceitem.data.statusCode;
            // console.log("staffitem.data[]", staffitem.data["attendanceStatusCode"]);
          }
          if( !staffitem.data.attendanceStatusCode ){
            staffitem.data["attendanceStatusCode"] = "U";
            // console.log("staffitem.data['U']", staffitem.data["attendanceStatusCode"]);
          }
        }
      }
    }
    
    console.log("staff list to show", staffListToShow);
    return (
      <List>
        {staffListToShow.map((item) => (
          <StaffAttendanceCard key={item.data.username} item={item}/>
        ))}
      </List>
    );
  }

  useEffect(() => {
    
    const converted = convert(date);
    const query = `date=${converted}`;
    fetchStaffData(dispatch);
    fetchAttendanceData(dispatch, query);
  }, [dispatch]);

  return (
    <>
      <HeaderInner pageTitle="Attendance"/>
      <div className="container--narrow HrmAppPage">
        <div className={classes.attendanceButtonWrapper}>
          {/* <div className={classes.attendanceButtonInliner}>
            <InOutSwitch /> 
          </div> */}
          <div className={classes.attendanceButton}>
            <IconButton 
              edge="start" 
              className={classes.menuButton} 
              color="default" 
              aria-label="menu"
              onClick={() => history.push(`/${activeOuid}/attendancesetup`)}
            >
              <SettingsIcon />
            </IconButton>
          </div>
        </div>
        
        <DatePicker onChange={onChange} selected={date} />
        <div>
            {/* {renderStaffList(staffs, attendance)} */}
        </div>
        {showGlobalLoader ? (
            <Loader />
          ) : (
            <>
              {renderStaffList(staffs, attendance)}
            </>
          )}
        <CommonSnackBar />
      </div>
    </>
    
  );
}

