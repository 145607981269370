import React, { Component } from 'react';
import SidebarWrapper from '../../layout/SidebarWrapper';
import { connect } from 'react-redux';

class OrgHome extends Component {
  render() {
    return (
      <div className="page">
        <SidebarWrapper
          auth={this.props.auth}
        />
        <section className="hero is-primary">
          <div className="hero-body">
            <div>
            </div>
            <div >
              <h1>Org Home</h1>
            </div>
          </div>
        </section>

      </div>
    )
  }
}


const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgHome);
