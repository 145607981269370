import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import Profile from '../assets/images/ibcImages/Profile.png';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
    },
    MyProfileImg: {
        margin: '0 auto',
    },
    MyProfileDataItem: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 10,
        fontSize: 14,
    },
    MyProfileData: {
        flex: 1,
        textAlign: 'left',
        paddingLeft: 15,
    },
    MyProfileDataLabel: {
        flex:1,
        textAlign: 'right',
        fontWeight: 'bold'
    }
}));

export default function MyProfileComponet(props) {
    const { profileDetails } = props;
    const classes = useStyles();
    console.log(profileDetails);
    return (
        <div className={classes.root}>
            <div className='container--narrow'>
                    {profileDetails &&
                        <div>
                            <Paper >
                                <img src={Profile} className={classes.MyProfileImg}/>
                                <div className={classes.MyProfileDataItem}>
                                    <div className={classes.MyProfileDataLabel}>Username: </div>
                                    <div className={classes.MyProfileData}>{ profileDetails.username }</div>
                                </div>
                                <div className={classes.MyProfileDataItem}>
                                    <div className={classes.MyProfileDataLabel}>Name: </div>
                                    <div className={classes.MyProfileData}>{ profileDetails.name }</div>
                                </div>
                                <div className={classes.MyProfileDataItem}>
                                    <div className={classes.MyProfileDataLabel}>Email: </div>
                                    <div className={classes.MyProfileData}>{ profileDetails.email }</div>
                                </div>
                                <div className={classes.MyProfileDataItem}>
                                    <div className={classes.MyProfileDataLabel}>Phone: </div>
                                    <div className={classes.MyProfileData}>{ profileDetails.phone_number }</div>
                                </div>
                                <div className={classes.MyProfileDataItem}>
                                    <div className={classes.MyProfileDataLabel}>Membership status: </div>
                                    <div className={classes.MyProfileData}>{ profileDetails.subscriptionStatus }</div>
                                </div>
                                <div className={classes.MyProfileDataItem}>
                                    <div className={classes.MyProfileDataLabel}>Company Name: </div>
                                    <div className={classes.MyProfileData}>{ profileDetails.defaultOrg }</div>
                                </div>
                            </Paper>
                        </div>
                    }
            </div>
        </div>
    );
}