import {
  GET_PAYROLL_REQUESTED,
  GET_PAYROLL_SUCCESS,
  GET_PAYROLL_ERRORED,
  GET_PAYROLL_PROFILE_REQUESTED,
  GET_PAYROLL_PROFILE_SUCCESS,
  GET_PAYROLL_PROFILE_ERRORED,
  CREATE_PAYROLL_PROFILE_REQUESTED,
  CREATE_PAYROLL_PROFILE_SUCCESS,
  CREATE_PAYROLL_PROFILE_ERRORED,
  UPDATE_PAYROLL_PROFILE_REQUESTED,
  UPDATE_PAYROLL_PROFILE_SUCCESS,
  UPDATE_PAYROLL_PROFILE_ERRORED,
  GET_ALL_PAYROLL_INSTANCE_REQUESTED,
  GET_ALL_PAYROLL_INSTANCE_SUCCESS,
  GET_ALL_PAYROLL_INSTANCE_ERRORED,
  CREATE_PAYROLL_INSTANCE_REQUESTED,
  CREATE_PAYROLL_INSTANCE_SUCCESS,
  CREATE_PAYROLL_INSTANCE_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: true,
  payroll: [],
  payrollprofile: [],
  payrollinstance: [],
  payrollinstanceprofile: [],
};

export function payroll(state = defaultState, { type, payload }) {
  switch (type) {

    case GET_PAYROLL_REQUESTED:
    // console.log("GET_ALL_STAFF_REQUESTED : ");
    return {
      ...state,
      showGlobalLoader: true,
      payroll: [],
    };

  case GET_PAYROLL_SUCCESS:
    // console.log("GET_ALL_STAFF_SUCCESS : ", JSON.stringify(payload));
    return {
      ...state,
      showGlobalLoader: false,
      payroll: payload,
    };

  case GET_PAYROLL_ERRORED:
    return {
      ...state,
      showGlobalLoader: false,
      error: payload,
    };
  
  case GET_PAYROLL_PROFILE_REQUESTED:
    // console.log("GET_ALL_STAFF_REQUESTED : ");
    return {
      ...state,
      showGlobalLoader: true,
      payrollprofile: [],
    };

  case GET_PAYROLL_PROFILE_SUCCESS:
    // console.log("GET_ALL_STAFF_SUCCESS : ", JSON.stringify(payload));
    return {
      ...state,
      showGlobalLoader: false,
      payrollprofile: payload,
    };

  case GET_PAYROLL_PROFILE_ERRORED:
    return {
      ...state,
      showGlobalLoader: false,
      error: payload,
    };

  case CREATE_PAYROLL_PROFILE_REQUESTED:
    return {
      ...state,
      showGlobalLoader: true,
      payrollprofile: {},
    };

  case CREATE_PAYROLL_PROFILE_SUCCESS:
    return {
      ...state,
      showGlobalLoader: false,
      payrollprofile: payload,
    };

  case CREATE_PAYROLL_PROFILE_ERRORED:
    return {
      ...state,
      showGlobalLoader: false,
      error: payload,
    };

  case UPDATE_PAYROLL_PROFILE_REQUESTED:
    return {
      ...state,
      showGlobalLoader: true,
      payrollprofile: {},
    };

  case UPDATE_PAYROLL_PROFILE_SUCCESS:
    return {
      ...state,
      showGlobalLoader: false,
      payrollprofile: payload,
    };

  case UPDATE_PAYROLL_PROFILE_ERRORED:
    return {
      ...state,
      showGlobalLoader: false,
      error: payload,
    };

  case CREATE_PAYROLL_INSTANCE_REQUESTED:
    return {
      ...state,
      showGlobalLoader: true,
      payrollprofile: {},
    };

  case CREATE_PAYROLL_INSTANCE_SUCCESS:
    return {
      ...state,
      showGlobalLoader: false,
      payrollprofile: payload,
    };

  case CREATE_PAYROLL_INSTANCE_ERRORED:
    return {
      ...state,
      showGlobalLoader: false,
      error: payload,
    };

  case GET_ALL_PAYROLL_INSTANCE_REQUESTED:
    // console.log("GET_ALL_STAFF_REQUESTED : ");
    return {
      ...state,
      showGlobalLoader: true,
      payrollinstanceprofile: {},
    };

  case GET_ALL_PAYROLL_INSTANCE_SUCCESS:
    // console.log("GET_ALL_STAFF_SUCCESS : ", JSON.stringify(payload));
    return {
      ...state,
      showGlobalLoader: false,
      payrollinstanceprofile: payload,
    };

  case GET_ALL_PAYROLL_INSTANCE_ERRORED:
    return {
      ...state,
      showGlobalLoader: false,
      error: payload,
    };
    
    default:
      return state;
  }
}
