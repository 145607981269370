import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./Header";
import configureStore from "../../../store/configureStore";
import CommonSnackBar from "../../common/CommonSnackBar";
import { setActiveOuid } from "../../../actions/action-permissions";
import { Button } from "@material-ui/core";
import "./App.css";
import { history } from "../../../reducers";
import InOutSwitch from "./common/InOutSwitch";
import { get_my_attendance } from "./actions/action-attendance";

import avatar from "./images/Frame.svg";
import staffsIcon from "./images/Staffs.svg";
import attendanceIcon from "./images/Attendence.svg";
import leaveIcon from "./images/Leaves.svg";
import payrollIcon from "./images/Payroll.svg";
import banner from "./images/Banner.svg";

const store = configureStore();

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("");
}

export default function Home() {
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { details } = store.getState().myProfile;

  const username = details.data.username;

  const { myAttendance } = useSelector((state) => state.attendance);

  console.log("myAttendance store", myAttendance);
  useEffect(() => {
    const today = convert(new Date());
    const query = `username=${username}&date=${today}`;
    const fetchData = async (dispatch) => {
      await dispatch(get_my_attendance(activeOuid, query));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div className="">
      <Header />
      <div className="HrmAppHomePage HrmAppPage container--narrow">
        <div className="HrmAppHeaderProfileWrapper">
          <div className="HrmAppHeaderProfileDetails">
            <img src={avatar} />
            <div>
              <div className="HrmAppHeaderProfileName">{details.data.name}</div>
              <div className="HrmAppHeaderProfileDesignation">
                {details.data.username}
              </div>
            </div>
          </div>
        </div>
        <div className="HrmAppHomeListWrapper">
          <div>
            <a href={`/${activeOuid}/staff`} className="HrmAppHomeListItem">
              <img src={staffsIcon} />
            </a>
            <p>Employees</p>
          </div>
          <div>
            <a
              href={`/${activeOuid}/attendance`}
              className="HrmAppHomeListItem"
            >
              <img src={attendanceIcon} />
            </a>
            <p>Attendance</p>
          </div>
          <div>
            <a href={`/${activeOuid}/leave`} className="HrmAppHomeListItem">
              <img src={leaveIcon} />
            </a>
            <p>Leave</p>
          </div>
          <div>
            <a href={`/${activeOuid}/payroll`} className="HrmAppHomeListItem">
              <img src={payrollIcon} />
            </a>
            <p>Payroll</p>
          </div>
        </div>
        <div className="HrmAppBanner">
          <img src={banner} />
        </div>
        {myAttendance ? (
          <InOutSwitch attendancedetails={myAttendance} />
        ) : (
          <></>
        )}
        <div className="HrmAppHomePageButtonWrapper">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => history.push(`/edwin/addstaff`)}
          >
            Add Employees
          </Button>
        </div>
      </div>
      <CommonSnackBar />
    </div>
  );
}
