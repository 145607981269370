import {
  REGISTER_STAFF_REQUESTED,
  REGISTER_STAFF_SUCCESS,
  REGISTER_STAFF_ERRORED,
  GET_ALL_STAFF_REQUESTED,
  GET_ALL_STAFF_SUCCESS,
  GET_ALL_STAFF_ERRORED,
  GET_STAFF_DETAILS_REQUESTED,
  GET_STAFF_DETAILS_SUCCESS,
  GET_STAFF_DETAILS_ERRORED,
  UPDATE_STAFF_REQUESTED,
  UPDATE_STAFF_SUCCESS,
  UPDATE_STAFF_ERRORED,
  DELETE_STAFF_REQUESTED,
  DELETE_STAFF_SUCCESS,
  DELETE_STAFF_ERRORED,
} from "../constant/action-types";

import axios from "axios";

import { showSnackbar } from "../../../../actions/action-snackbar";

import configureStore from "../../../../store/configureStore";

const store = configureStore();

export const register_staff_requested = () => ({
  type: REGISTER_STAFF_REQUESTED,
});

export const register_staff_success = () => ({
  type: REGISTER_STAFF_SUCCESS,
});

export const register_staff_errored = (error) => ({
  type: REGISTER_STAFF_ERRORED,
  error,
});

export const get_all_staff_requested = () => ({
  type: GET_ALL_STAFF_REQUESTED,
});

export const get_all_staff_success = (payload) => ({
  type: GET_ALL_STAFF_SUCCESS,
  payload,
});

export const get_all_staff_errored = (error) => ({
  type: GET_ALL_STAFF_ERRORED,
  error,
});

export const get_staff_details_requested = () => ({
  type: GET_STAFF_DETAILS_REQUESTED,
});

export const get_staff_details_success = (payload) => ({
  type: GET_STAFF_DETAILS_SUCCESS,
  payload,
});

export const get_staff_details_errored = (error) => ({
  type: GET_STAFF_DETAILS_ERRORED,
  error,
});

export const update_staff_requested = () => ({
  type: UPDATE_STAFF_REQUESTED,
});

export const update_staff_success = (payload) => ({
  type: UPDATE_STAFF_SUCCESS,
  payload,
});

export const update_staff_errored = (error) => ({
  type: UPDATE_STAFF_ERRORED,
  error,
});

export const delete_staff_requested = () => ({
  type: DELETE_STAFF_REQUESTED,
});

export const delete_staff_success = (payload) => ({
  type: DELETE_STAFF_SUCCESS,
  payload,
});

export const delete_staff_errored = (error) => ({
  type: DELETE_STAFF_ERRORED,
  error,
});
export const register_staff =
  (payload, activeOuid, activeOuidChain) => async (dispatch) => {
    dispatch(register_staff_requested());
    console.log(`register_STAFF`);
    const { hrmApiEndpoint, credentials } = store.getState().jwt.api;
    if (hrmApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${hrmApiEndpoint}/staff`;
      const method = "post";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("STAFF Registerd Successfully", response);
          dispatch(showSnackbar("STAFF Registerd Successfully", "success"));
          dispatch(register_staff_success(response));
        })
        .catch((err) => {
          console.log("STAFF Registration Failed");
          dispatch(showSnackbar("STAFF Registration Failed", "error"));
          dispatch(register_staff_errored(err));
        });
    }
    return null;
  };

export const update_staff =
  (payload, activeOuid, username) => async (dispatch) => {
    dispatch(update_staff_requested());
    console.log(`update_STAFF`);
    const ecodedUserName = encodeURIComponent(username);
    var payloadData = { data: payload };

    const { hrmApiEndpoint, credentials } = store.getState().jwt.api;
    if (hrmApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${hrmApiEndpoint}/staff/${ecodedUserName}`;
      const { activeOuid, activeOuidChain } = store.getState().permission;
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payloadData, axiosHeader)
        .then((response) => {
          console.log("Employee details Updation Success");
          dispatch(showSnackbar("Employee details Updated Successfully", "success"));
          dispatch(update_staff_success());
        })
        .catch((err) => {
          console.log("Employee details updation Failed");
          dispatch(showSnackbar("Employee details updation Failed", "error"));
          dispatch(update_staff_errored(err));
        });
    }
    return null;
  };

export const get_all_staff = (activeOuid) => async (dispatch) => {
  dispatch(get_all_staff_requested());
  console.log(`get_all_STAFF`);
  console.log(`hrmApiEndpoint`);

  const { hrmApiEndpoint, credentials } = store.getState().jwt.api;
  if (hrmApiEndpoint && credentials) {
    console.log(`hrmApiEndpoint`);

    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${hrmApiEndpoint}/staff`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get All STAFF Success");
        dispatch(get_all_staff_success(response.data));
        console.log("Store staffs", store.getState().staffs);
      })
      .catch((err) => {
        console.log("Get All STAFF Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get All STAFFs Failed", "error"));
        }
        dispatch(get_all_staff_errored(err));
      });
  }
  return null;
};

export const get_staff_details = (activeOuid, username) => async (dispatch) => {
  dispatch(get_staff_details_requested());
  console.log(`get_STAFF_details`);
  const { hrmApiEndpoint, credentials } = store.getState().jwt.api;
  if (hrmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${hrmApiEndpoint}/staff/${username}`;
    // console.log(`jwt : ${jwt}`);
    // console.log(`url : ${url}`);
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get STAFF Details Success");
        // dispatch(showSnackbar('Get All Channel Partner Successfully', 'success'));
        dispatch(get_staff_details_success(response.data));
      })
      .catch((err) => {
        console.log("Get STAFF Details  Failed");
        // console.error('ErrorLog : ', JSON.stringify(err));
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get STAFF Details Failed", "error"));
        }
        dispatch(get_staff_details_errored(err));
      });
  }
  return null;
};

export const delete_staff = (activeOuid, username) => async (dispatch) => {
  console.log(username);
  dispatch(delete_staff_requested());
  const ecodedUserName = encodeURIComponent(username);
  console.log(`delete_STAFF`);
  const { hrmApiEndpoint, credentials } = store.getState().jwt.api;
  if (hrmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${hrmApiEndpoint}/staff/${ecodedUserName}`;
    const method = "delete";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Delete STAFF Success");
        dispatch(
          showSnackbar("STAFF Deletion Requested Successfully", "success")
        );
        dispatch(delete_staff_success(response.data));
      })
      .catch((err) => {
        if (err.response) {
          console.log(
            "Delete STAFF  Failed :",
            JSON.stringify(err.response.data)
          );
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Delete failed", "error"));
        }
        dispatch(delete_staff_errored(err));
      });
  }
  return null;
};
