import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../../store/configureStore";
import {
  get_course,
  get_courselines,
  get_courselines_public,
  get_course_public,
  create_update_courseline,
} from "../../../actions/action-course";
import Loader from "../../common/Loader";
import { history } from "../../../reducers";
import SidebarWrapper from "../../layout/SidebarWrapper";
import Player from "../Vod/Player";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from '@material-ui/icons/Edit';
import CommonDelete from "../../common/CommonDelete";
import { timeFormat } from "../../common/CommonFunctions";
import CommonSnackBar from "../../common/CommonSnackBar";
import { hideSnackbar } from "../../../actions/action-snackbar";
import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
const store = configureStore();

class CourseDetails extends Component {
  constructor() {
    super();
    this.state = {
      courseDetails: {},
      signedUrl: "",
      courselines: [],
      newchapterTitle: "",
      newChapterPreviewable: "No",
      currentVideo: "",
      currentSummary: "",
      featuredImage: "",
      currentSequenceNumber: "",
      addChapterVisibility: false,
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.fetchPublicDetails();
    } else {
      this.fetchDetails();
    }
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }
  componentWillUnmount() {}

  async fetchDetails() {
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    console.log("store.getState().permission : ", store.getState().permission);
    const { activeOuid, activeOuidChain, userRoles } =
      store.getState().permission;

    const { get_course, get_courselines } = this.props;
    await get_course(activeOuid, activeOuidChain, id);
    await get_courselines(activeOuid, id);
    console.log("fetched course details: ", store.getState().course);
    this.setState({
      courseDetails: store.getState().course.courseDetails,
      courselines: store.getState().course.courselines,
    });
    if (this.state.courselines.length > 0) {
      this.state.courselines.forEach((element) => {
        console.log("element.data.sequenceNumber", element.data.sequenceNumber);
        if (element.data.sequenceNumber === "1") {
          console.log("element.data.courseVideo", element.data.courseVideo);
          element["currentSelection"] = "true";

          this.setState({
            currentVideo: element.data.courseVideo,
            currentSequenceNumber: element.data.sequenceNumber,
            currentSummary: element.data.summary,
            currentTitle: element.data.title,
            featuredImage: element.data.featuredImage,
          });
        }
      });
    }
  }

  async fetchPublicDetails() {
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    let ouid = path[1];
    console.log("ouid : ", ouid);

    const { get_course_public, get_courselines_public } = this.props;
    await get_course_public(ouid, id);
    await get_courselines_public(ouid, id);

    console.log("fetched course details: ", store.getState().course);
    this.setState({
      courseDetails: store.getState().course.courseDetails,
      courselines: store.getState().course.courselines,
    });
    if (this.state.courselines.length > 0) {
      this.state.courselines.forEach((element) => {
        console.log("element.data.sequenceNumber", element.data.sequenceNumber);
        if (element.data.sequenceNumber === "1") {
          console.log("element.data.courseVideo", element.data.courseVideo);
          element["currentSelection"] = "true";

          this.setState({
            currentVideo: element.data.courseVideo,
            currentSequenceNumber: element.data.sequenceNumber,
            currentSummary: element.data.summary,
            currentTitle: element.data.title,
            featuredImage: element.data.featuredImage,
          });
        }
      });
    }
  }

  async addChapter() {
    const { activeOuid } = store.getState().permission;
    console.log("Add Chapter");
    const courseId = store.getState().course.courseDetails.data.identifier;
    console.log("courseId ", courseId);

    let payload = {
      courseId: courseId,
      title: this.state.newchapterTitle,
      courseVideo: this.state.newchapterVideo,
      featuredImage: this.state.newchapterFeaturedImage,
      summary: this.state.newchapterSummary,
      sequenceNumber: this.state.newchapterSequenceNumber,
      previewable: this.state.newChapterPreviewable,
    };
    await store.dispatch(create_update_courseline("", activeOuid, payload, ""));
  }

  onInputChange = (event) => {
    console.log("event.target.id", event.target.id);
    console.log("event.target.value", event.target.value);

    if (event.target.id) {
      this.setState({ ...this.state, [event.target.id]: event.target.value });
    } else {
      this.setState({ ...this.state, [event.target.name]: event.target.value });
    }
  };

  setAddChapterVisibility(visibility) {
    console.log("addChapterVisibility", this.state.addChapterVisibility);
    this.setState({
      addChapterVisibility: visibility,
    });
  }

  renderCourse() {
    const { showGlobalLoader, showGlobalLoaderCourseline } =
      store.getState().course;
    const { activeOuid, userRoles, policies, activeOuidType } =
      store.getState().permission;
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    console.log("activeOuidType in renderCourse : ", activeOuidType);

    if (
      activeOuidType === "ROOT" &&
      (policies.includes(`${userRoles[0].ouid}=admin`) ||
        policies.includes(`system=admin`))
    ) {
      console.log("return for ROOT : ");
      console.log("showGlobalLoader : ", showGlobalLoader);
      console.log("this.state.courseDetails : ", this.state.courseDetails);
      return (
        <div>
          <Paper component="div" className="paper">
            <div className="listHeader">
              <h1>Course Details</h1>
              <CommonDelete id={id} type="Course" />
            </div>
            {store.getState().course.courseDetails &&
            store.getState().course.courseDetails.data
              ? <div>{this.renderDetails(store.getState().course.courseDetails.data)}</div>
              : "No Course Details Found"}
            <div className="listHeader">
              <div>
                <h2>
                  Chapters{" "}
                  {/* <IconButton
                    color="primary"
                    onClick={() =>
                      this.setAddChapterVisibility(
                        !this.state.addChapterVisibility
                      )
                    }
                  >
                    <AddCircleIcon />
                  </IconButton> */}

                  <Button
                    onClick={() =>
                      this.setAddChapterVisibility(
                        !this.state.addChapterVisibility
                      )
                    }
                    variant="contained"
                    color="primary"
                  >
                    Add Chapter
                  </Button>
                </h2>

                {this.state.addChapterVisibility && (
                  <div className="courseAddChaptersForm">
                    <h2>Add a new Chapter</h2>
                    <div className="form-item-wrapper">
                      <TextField
                        className="input"
                        id="newchapterTitle"
                        placeholder="Title"
                        onChange={this.onInputChange}
                        fullWidth
                        variant="outlined"
                      />
                    </div>
                    <div className="form-item-wrapper">
                      <TextField
                        className="input"
                        id="newchapterSummary"
                        placeholder="Summary"
                        onChange={this.onInputChange}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                      />
                    </div>
                    <div className="form-item-wrapper">
                      <TextField
                        className="input"
                        id="newchapterFeaturedImage"
                        placeholder="Featured Image"
                        onChange={this.onInputChange}
                        fullWidth
                        variant="outlined"
                      />
                    </div>
                    <div className="form-item-wrapper">
                      <TextField
                        className="input"
                        id="newchapterVideo"
                        placeholder="Course Video - upload to s3 and give url here"
                        onChange={this.onInputChange}
                        fullWidth
                        variant="outlined"
                      />
                    </div>
                    <div className="form-item-wrapper">
                      <TextField
                        className="input"
                        id="newchapterSequenceNumber"
                        placeholder="new chapter sequence number"
                        onChange={this.onInputChange}
                        fullWidth
                        variant="outlined"
                        type="number"
                      />
                    </div>
                    <div className="form-item-wrapper">
                      <label>Show preview to unregistered users?... </label>
                      <select
                        id="newChapterPreviewable"
                        onChange={this.onInputChange}
                        fullWidth
                      >
                        <option value="yes">Yes</option>
                        <option selected value="no">
                          No
                        </option>
                      </select>
                    </div>

                    <Button
                      onClick={() => {
                        this.addChapter();
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Add
                    </Button>
                  </div>
                )}
                <div>
                  {showGlobalLoaderCourseline ? (
                    <Loader />
                  ) : store.getState().course.courselines.length !== 0 &&
                    store.getState().course.courseDetails.data ? (
                    <div>
                      {this.renderCourselinesForAdmin(
                        store.getState().course.courselines,
                        store.getState().course.courseDetails.data.identifier
                      )}
                    </div>
                  ) : (
                    "No Existing Course Chapters....."
                  )}
                </div>
              </div>
            </div>
          </Paper>
          <CommonSnackBar />
        </div>
      );
    } else {
      console.log("return for non root : ");
      console.log(
        "store.getState().course.courseDetails : ",
        store.getState().course.courseDetails
      );

      return (
        <div className="page">
          <div className="section">
            <div className="container">
            {showGlobalLoader ? (
              <Loader />
            ) : (
            <div className="CourseGrid">
              <div className="CourseVideoWrapper">
                <div className="currentVideo">
                  {showGlobalLoaderCourseline ? (
                    <Loader />
                  ) : store.getState().course.courselines.length !== 0 ? (
                    <div>
                      {this.state.currentVideo ? (
                        <Player urlSrc={this.state.currentVideo} />
                      ) : (
                        ""
                      )}
                      <div className="CourseModuleTitle">
                        <h2>{this.state.currentTitle}</h2>
                      </div>
                      <div>Chapter: {this.state.currentSequenceNumber}</div>
                      <div>{this.state.currentSummary}</div>
                    </div>
                  ) : (
                    "No Existing Course Chapters....."
                  )}
                </div>
                <div className="CourseDetailsWrapper">
                  <div className="">
                    {store.getState().course.courseDetails &&
                    store.getState().course.courseDetails.data ? (
                      <h1>
                        {store.getState().course.courseDetails.data.title}
                      </h1>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="">
                    {store.getState().course.courseDetails &&
                    store.getState().course.courseDetails.data ? (
                      <>{store.getState().course.courseDetails.data.summary}</>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <Paper className="CourseModuleWrapper">
                {showGlobalLoaderCourseline || showGlobalLoader ? (
                  <Loader />
                ) : store.getState().course.courselines.length !== 0 ? (
                  <div>
                    {this.renderCourselines(
                      store.getState().course.courselines,
                      store.getState().course.courseDetails.data.identifier
                    )}
                  </div>
                ) : (
                  "No Existing Course Chapters....."
                )}
              </Paper>
            </div>
          )}
          </div>
          </div>
        </div>
      );
    }
  }

  renderDetails(details) {
    return Object.keys(details).map(function (keyName, keyIndex) {
      return (
        <div key={keyName} className="detailsItem">
          <div className="detailsKey">{keyName}</div>
          {keyName === "lastModifiedDate" || keyName === "createdDate" ? (
            <div className="detailsValue">{timeFormat(details[keyName])}</div>
          ) : (
            <div className="detailsValue">{details[keyName]}</div>
          )}
        </div>
      );
    });
  }

  renderCourselines = (courselines, courseId) => {
    const { activeOuid } = store.getState().permission;
    return courselines.map((item, index) => (
      <>
        {" "}
        <div
          className={
            item.currentSelection
              ? "CourseModuleTumbnail Active"
              : "CourseModuleTumbnail"
          }
          key={item.data.identifier}
          onClick={() => {
            this.state.courselines.forEach((element) => {
              if (element["currentSelection"] === "true") {
                delete element["currentSelection"];
              }
              if (element.data.identifier === item.data.identifier) {
                element["currentSelection"] = "true";
              }
            });
            this.setState({
              currentVideo: `${item.data.courseVideo}`,
              currentSequenceNumber: `${item.data.sequenceNumber}`,
              currentSummary: `${item.data.summary}`,
              currentTitle: `${item.data.title}`,
            });
          }}
        >
          <div>
            <img src={item.data.featuredImage} />
          </div>
          <div>
            <div className="CourseModuleTitle">{item.data.title}</div>
            {/* <div>Chapter: {item.data.sequenceNumber}</div> */}
          </div>

          {/* <div>
            <Player
              urlSrc={
                item.data.courseVideo
              }
            />
            Video : <strong>{item.data.courseVideo}</strong>
          </div> */}
        </div>
      </>
    ));
  };

  renderCourselinesForAdmin = (courselines, courseId) => {
    const { activeOuid } = store.getState().permission;
    const courselinesSorted = courselines.sort((a,b)=> a.data.sequenceNumber-b.data.sequenceNumber);
    return courselinesSorted.map((item, index) => (
      <>
        {" "}
        <div
          className="courseLineItemAdmin"
          key={item.data.identifier}
          onClick={() => {
            this.state.courselines.forEach((element) => {
              if (element["currentSelection"] === "true") {
                delete element["currentSelection"];
              }
              if (element.data.identifier === item.data.identifier) {
                element["currentSelection"] = "true";
              }
            });
            this.setState({
              currentVideo: `${item.data.courseVideo}`,
              currentSequenceNumber: `${item.data.sequenceNumber}`,
              currentSummary: `${item.data.summary}`,
              currentTitle: `${item.data.title}`,
            });
          }}
        >
          <div>
            <img src={item.data.featuredImage} />
          </div>
          <div>
            <div>
              <strong>Title : </strong>
              {item.data.title}
            </div>
            <div>
              <strong>SequenceNumber : </strong>
              {item.data.sequenceNumber}
            </div>
            <div>
              <strong>FeaturedImage : </strong>
              {item.data.featuredImage}
            </div>
            <div>
              <strong>CourseVideo : </strong>
              {item.data.courseVideo}
            </div>
            <div>
              <strong>Previewable : </strong>
              {item.data.previewable}
            </div>
            <div className="courseLineItemAdminBtnWrap">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => {
                  history.push(`/${activeOuid}/mng-test/${item.data.identifier}`);
                }}
              >
                Test Questions & Answers
              </Button>
              {/* <IconButton
                color="primary"
                onClick={() =>
                  this.setAddChapterVisibility(
                    !this.state.addChapterVisibility
                  )
                }
              >
                <EditIcon />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() =>
                  this.setAddChapterVisibility(
                    !this.state.addChapterVisibility
                  )
                }
              >
                <DeleteIcon />
              </IconButton> */}
            </div>
            
          </div>
        </div>
      </>
    ));
  };

  render() {
    const { showGlobalLoader } = store.getState().course;
    const { permissions } = store.getState().permission;
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];

    return (
      <div className="page">
        {permissions ? (
          <SidebarWrapper auth={this.props.auth} permissions={permissions} />
        ) : (
          ""
        )}
        <SidebarWrapper auth={this.props.auth} permissions={permissions} />
        <div className="section">
          <div className="container">{this.renderCourse()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  course: state.course,
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  get_course: (activeOuid, activeOuidChain, id) =>
    dispatch(get_course(activeOuid, activeOuidChain, id)),
  get_course_public: (activeOuid, id) =>
    dispatch(get_course_public(activeOuid, id)),

  get_courselines: (activeOuid, id) =>
    dispatch(get_courselines(activeOuid, id)),
  get_courselines_public: (activeOuid, id) =>
    dispatch(get_courselines_public(activeOuid, id)),
  create_update_courseline: (activeOuid, courseId) =>
    dispatch(create_update_courseline(activeOuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetails);
